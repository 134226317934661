import {createRouter, createWebHistory} from 'vue-router'

import Home from '@/views/pages/Home.vue'
import Navigation from '@/views/pages/Navigation.vue'
import Contacts from "@/views/pages/Contacts.vue";
import About from "@/views/pages/About.vue";
import Services from "@/views/pages/Services.vue";
import OurSoftware from "@/views/pages/OurSoftware.vue";
import Overview from "@/views/pages/Overview.vue";
import OnlineService from "@/views/pages/OnlineService.vue";
import Consultancy from "@/views/pages/Consultancy.vue";
import ServicesNavigation from "@/views/pages/ServicesNavigation.vue";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
  },
  {
    path: '/menu',
    name: 'NavigationPage',
    component: Navigation,
  },
   {
    path: '/contacts',
    name: 'ContactsPage',
    component: Contacts,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: About,
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: Services,
  },
  {
    path: '/our-software',
    name: 'OurSoftwarePage',
    component: OurSoftware,
  },
  {
    path: '/overview',
    name: 'OverviewPage',
    component: Overview,
  },
  {
    path: '/online-service',
    name: 'OnlineService',
    component: OnlineService,
  },
  {
    path: '/consultancy',
    name: 'ConsultancyPage',
    component: Consultancy,
  },
  {
    path: '/services-menu',
    name: 'ServicesNavigation',
    component: ServicesNavigation,
  },





]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;