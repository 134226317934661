import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': '*'
  },
});

export default instance;
