<template>
  <div class="container-fluid bg">
    <HeaderNavComponent />
    <div class="menu col-xl-6">
      <div class="container p-0">
        <div class="menu-wrap">
          <div class="title">
            <p>services</p>
          </div>
          <div class="list">
            <ul>
              <router-link to="/overview">
                <li>Overview</li>
              </router-link>

              <router-link to="/our-software">
                <li>  Our Software </li>
              </router-link>

              <router-link to="/online-service">
                <li> Online Service </li>
              </router-link>
              <router-link to="/consultancy">
                <li> Consultancy </li>
              </router-link>
            </ul>
          </div>
        </div>

        <div class="button-wrap">
          <button
              class="d-flex align-items-center justify-content-center"
              @click="this.$router.push('/menu')"
          >
            <img src="@/assets/img/icons/menu-link-active.svg">
            RETURN
          </button>
        </div>

        <div class="socials">
          <img src="@/assets/img/socials-icons/facebook.svg" alt="" class="s-img">
          <img src="@/assets/img/socials-icons/inst.svg" alt="">
          <img src="@/assets/img/socials-icons/twitter.svg" alt="">
        </div>
      </div>
    </div>
    <div class="col-xl-6  img-container">
      <img src="@/assets/img/menu/serv-col.png" alt="" class="resorts">
      <img src="@/assets/img/menu/serv-col-sm.png" alt="" class="sm-resorts" >


      <div class="socials  flex-column">
        <img src="@/assets/img/socials-icons/facebook.svg" alt="" class="s-img">
        <img src="@/assets/img/socials-icons/inst.svg" alt="">
        <img src="@/assets/img/socials-icons/twitter.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import HeaderNavComponent from "@/components/HeaderNav.vue";

export default defineComponent({
  components: {HeaderNavComponent}
})
</script>

<style scoped lang="scss">
.container-fluid {
  padding:  0 20px 0 20px;
}
.header {
  padding-top: 25px;

  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 55px;
  }
}
.container {
  .title {
    margin-top: 80px;
    p {
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 5px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

  .button-wrap {
    margin-top: 12%;
    button {
      background: none;
      border: none;
      color: white;
      width: 100%;
      font-family: Khand;
      font-size: 28px;
      line-height: 43px;

      img {
        margin-right: 5%;
        width: 20px;
        height: 20px;
      }
    }
  }

.list {
  margin-top: 15%;
  position: relative;
  ul {
    padding: 0;
    a {
      text-decoration: none;
    }
    li {
      color: #666666;
      list-style: none;
      font-family: Khand;
      height: 60px;
      font-size: 48px;
      font-weight: 400;
      line-height: 60px;
      margin-bottom: 5%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    li:hover {
      color: #FFFFFF;
    }
    .menu-link {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 18%;
      top: 33%;
    }
    .menu-link:hover  {
      content: url("@/assets/img/icons/menu-link-active.svg");
    }
  }
}

.img-container {
  display: none;
}
.socials {
  display: flex;
  margin-top: 73px;
  padding-bottom: 21.3%;
  justify-content: center;

  img {
    margin: 17.5px;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .container-fluid {
    padding: 0 40px 0 40px;
  }

  .header {
    padding-top: 40px;
    .title {
      font-size: 40px;
      line-height: 61.16px;
    }
    img {
      width: 48px;
    }
  }

  .container {
    .title {
      margin-top: 18%;
    }
    .list {
      margin-top: 6%;
      ul {
        li {
          font-size: 60px;
          line-height: 60px;
          margin-bottom: 7%;
        }
        .menu-link {
          width: 32px;
          height: 32px;
          right: 35%;
          top: 32%;
        }
      }
    }
    .button-wrap {
      margin-top: 5%;
      button img {
        margin-right: 2%;
      }
    }
    .socials {
      margin-top: 11.1rem;
      padding-bottom: 193px;

      img {
        width: 44px;
        margin: 2%;
      }
    }
  }
}

@media (min-width: 1366px) {
  .container-fluid {
    display: flex;
    flex-wrap: wrap;
  }
  .header {
    width: 100%;
  }
  .menu {
    .container {
      .socials {
        display: none;
      }

      .menu-wrap {
        .title {
          margin-top: 10%;
        }
        .list {
          ul {
            li {
              font-size: 48px;
              line-height: 60px;
              margin-bottom: 4%;
            }
            .menu-link {
              width: 24px;
              height: 24px;
              right: 32%;
              top: 32%;
            }
          }
        }
      }
    }
  }

  .img-container {
    display: flex;
    position: relative;

    .resorts {
      display: block;
      width: 475px;
      height: 535px;
    }
    .sm-resorts {
      display: block;
      position: absolute;
      width: 156px;
      height: 156px;
      top: 20%;
      right: 10%;
    }
    .socials {
      display: flex;
      margin-left: 7%;
      margin-top: 54%;
      padding-bottom: 66px;

      img {
        margin: 30px 0 0 0;
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (min-width: 1512px) {
  .container-fluid {
    .menu .container {
      .menu-wrap {
        .title {
          margin-top: 15%;
          margin-bottom: 9%;
        }
        .list {
          ul {
            li {
              font-size: 60px;
              line-height: 60px;
              margin-bottom: 7%;
            }
            .menu-link {
              width: 32px;
              height: 32px;
              right: 30%;
            }
          }
        }
      }
    }
    .img-container {
      margin-top: 61px;
      margin-bottom: 197px;

      .resorts {
        width: 550px;
        height: 620px;
      }
      .sm-resorts {
        width: 180px;
        height: 180px;
        top: 26%;
        right: 6%;
      }
      .socials {
        margin-left: 5%;
        margin-top: 61%;
        padding: 0;
      }
    }
  }
}

@media (min-width: 1920px) {
  .container-fluid {
    .menu .container {
      .menu-wrap {
        .list {
          ul {
            .menu-link {
              right: 34%;
            }
          }
        }
      }
    }
    .img-container {
      margin-bottom: 153px;
      .socials {
        margin-top: 51%;
      }
      .resorts {
        width: 577px;
        height: 650px ;
      }
      .sm-resorts {
        width: 189px;
        height: 189px;
        top: 19%;
        right: 18%;
      }
    }
  }
}
</style>