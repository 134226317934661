<template>
  <div class="container-fluid bg">
    <the-header />
    <div class="container p-0 title-wrap">
      <div class="title">
        <p>Let’s talk</p>
      </div>
      <div class="info">
        <p> info@tribalresorts.com </p>
        <p> +31 6 52 800 660 </p>
      </div>
    </div>
    <div class="container p-0 content-wrap">
      <div class="direction">
        <div class="img-wrapper">
          <img src="@/assets/img/contacts/contacts-large.png" class="contacts-large">
          <img src="@/assets/img/contacts/contacts-sm.png" class="contacts-sm">
        </div>
        <div class="socials justify-content-center align-items-center">
          <img src="@/assets/img/socials-icons/facebook.svg" alt="">
          <img src="@/assets/img/socials-icons/inst.svg" alt="">
          <img src="@/assets/img/socials-icons/twitter.svg" alt="">
        </div>
      </div>

      <div class="form-wrap">
        <div class="title">
          <p>
            Drop us a message
          </p>
        </div>
        <div class="form">
          <form @submit.prevent="submitForm" ref="form" class="d-flex">
            <div class="form-group">
              <input
                  type="text"
                  placeholder="Name*"
                  class="input"
                  v-model.trim="name"
                  @blur="v$.name.$touch"
                  @input="formatInput('name')"
              />
              <span  v-if="v$.name.$error" class="error-span"> {{  v$.name.$errors[0].$message }} </span>
            </div>
            <div class="form-group">
              <input
                  type="text"
                  name="email"
                  @input="formatInput('email')"
                  v-model="email"
                  placeholder="Email*"
                  @blur="v$.email.$touch"
              />
              <span v-if="v$.email.$error" class="error-span"> {{  v$.email.$errors[0].$message }} </span>
            </div>
            <div class="form-group">
              <input
                  v-model="phone"
                  name="phone"
                  @input="formatInput( 'phone')"
                  class="input"
                  placeholder="Phone*"
                  @blur="v$.phone.$touch"
              />
              <span v-if="v$.phone.$error" class="error-span"> {{  v$.phone.$errors[0].$message }} </span>
            </div>

            <div class="form-group">
              <input placeholder="Subject*" disabled />
            </div>

            <div class="form-group">
              <textarea
                placeholder="You message*"
                v-model="text"
                type="text"
                name="message"
                class="custom-input"
                @blur="v$.text.$touch"
              />
              <span v-if="v$.text.$error" class="error-span textarea-span"> {{  v$.text.$errors[0].$message }} </span>
            </div>



            <button type="submit" class="submit-button">
              <img src="@/assets/img/icons/submit.svg"  class="submit">
            </button>
          </form>

          <Toast :toast="toast" />
          <div class="overlay" v-if="overlay"></div>

        </div>
      </div>
    </div>
    <div class="socials-lg container-lg justify-content-center align-items-center">
      <img src="@/assets/img/socials-icons/facebook.svg" alt="">
      <img src="@/assets/img/socials-icons/inst.svg" alt="">
      <img src="@/assets/img/socials-icons/twitter.svg" alt="">
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import Toast from "@/components/Toast.vue";

import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength  } from '@vuelidate/validators'

export  default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'ContactsPage',
  components: {TheHeader, Toast},
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      text: '',
      overlay: false,
      toast: {
        show: false,
        message: 'Thank you, your request has been accepted',
        img_url: 'send-success',
        isSuccess: true
      },
    }
  },
  validations () {
    return {
      name: { required,  minLength: minLength(2) },
      phone: { required, minLength: minLength((7)) },
      email: { required, email },
      text: { required },
    }
  },

  methods: {
    formatInput(vModel) {
      if (vModel === "name") {
        const x = this[vModel].replace(/[&/\\#,+()$~%@!'":*?+=<>{}0-9]/g, "");
        this[vModel] = x.substring(0, 50);
      } else if (vModel === "phone") {
        var x = this[vModel].replaceAll(/\D/g, "");
        if (!x.startsWith('+')) {
          x = '+' + x;
        }
        this[vModel] = x;
      } else if (vModel === "email") {
        const x = this[vModel].replace(/[&/\\#,+()$~%^'":*?<>{ }]/g, "");
        this[vModel] = x.substring(0, 100);
      }
    },

     async submitForm() {
      const result = await this.v$.$validate()
       console.log(result)
       if (!result) return
       if (this.v$.$pending) {
         console.log('Validation is still pending');
         return;
       }

        this.overlay = true;
        this.toast.show = true
        this.toast.isSuccess = false
        this.toast.message = 'Process...'
        this.toast.img_url = 'loader'

        this.$store.dispatch('sendContacts', {
          email: this.email,
          name: this.name,
          phone: this.phone,
          text: this.text,
        })

        .then(() => {
          this.toast.isSuccess = true;
          this.overlay = true;
          this.toast.message = 'Thank you, your request has been accepted'
          this.toast.img_url = 'send-success'

          setTimeout(() => {
            this.toast.show = false;
            this.overlay = false;

            this.v$.$reset();
            this.text = "";
            this.email = "";
            this.name = "";
            this.phone = "";

          }, 3000);
        })

        .catch((err) => {
          console.error('Error sending contact data:', err);
        });
      }
    }
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 4;
}

.title-wrap {
  margin-top: 12%;
  .title {
    p {
      font-size: 72px;
      line-height: 72px;
    }
  }
  .info {
    margin-top: 5%;
    p {
      font-size: 32px;
      line-height: 40px;
      font-weight: 400;
      margin-bottom: 3%;
    }
  }
}
.content-wrap {
  .img-wrapper {
    position: relative;
    margin-top: 10%;

    .contacts-large {
      width: 285px;
      height: 385px;
    }
    .contacts-sm {
      width: 137px;
      height: 137px;
      position: absolute;
      right: -20px;
      top: 15%;
    }

  }
}
.socials-lg {
  display: none;
}
.socials {
  display: block;
  margin-top: 10%;
  img {
    margin-right: 5%;
  }
}
.form-wrap {
  margin-top: 15%;
  .title {
    p {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .form {
    margin-top: 5%;

    form {
      flex-direction: column;
      justify-content: center;

      .form-group {
        position: relative;

        input {
          height: 58px;
          width: 350px;
          margin-bottom: 5%;
          background-color: #303030 ;
          border: none;
          display: flex;
          align-items: center;
          padding-left: 5%;
          color: white;
          font-family: Khand;
          font-size: 22px;
          line-height: 34px;
        }
        .error-span {
          color: red;
          position: absolute;
          bottom: -3%;
        }
        textarea {
          padding-top: 3%;
          height: 180px;
          width: 350px;
          background-color: #303030 ;
          border: none;
          padding-left: 5%;
          font-family: Khand;
          font-size: 22px;
          line-height: 34px;
          color: white;
        }
        .textarea-span {
          margin-top: 0;
          position: absolute;
          bottom: -9%;
          left: 0;
        }
      }

      .submit-button {
        background: none;
        border: none;
        margin-top: 10%;
        margin-bottom: 30%;
      }
    }
  }
}


@media (min-width: 1024px) {
  .title-wrap {
    margin-top: 5%;
    .title p {
      font-size: 100px;
      line-height: 100px;
    }
    .info  {
      display: flex;
      p {
        font-size: 40px;
        line-height: 60px;
        padding-right: 7%;
      }
    }
  }
  .content-wrap {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;

    .form-wrap {
      width: 50%;
      margin-top: 1%;
      position: relative;

      .title p {
        font-size: 48px;
        line-height: 73px;
      }
      .form form {
        flex-wrap: wrap;

        .form-group {
          input {
            width: 462px;
            height: 60px;
          }
          textarea {
            width: 462px;
            height: 240px;
          }
        }

        .submit-button {
          position: absolute;
          top: 85%;
          right: -14%;
        }
      }
    }
    .img-wrapper {
      margin-top: 31%;

      .contacts-large {
        width: 350px;
        height: 474px;
        margin-right: 10%;
      }
      .contacts-sm {
        width: 189px;
        height: 190px;
        right: -40px;
        top: 85%;
      }
    }
    .socials {
      display: none;
    }
  }
  .socials-lg {
    display: flex;
    margin-top: 15%;
    padding-bottom: 8.5%;

    img {
      width: 44px;
      height: 44px;
      margin: 2%;
    }
  }
}


@media (min-width: 1366px) {
  .header {
    padding-top: 26px;
  }
  .title-wrap {
    margin-left: 0;
    margin-top: 3%;

    .title p {
      font-size: 90px;
      line-height: 90px;
    }
    .info {
      margin-top: 2%;
      p {
        font-size: 32px;
        line-height: 60px;
        padding-right: 10%;
      }
    }
  }
  .content-wrap {
    .form-wrap {
      margin-top: -2%;
      width: 100%;

      .form {
        margin-top: 0;
        margin-bottom: 66px;

        form {
          flex-direction: row;
          justify-content: start;
          flex-wrap: wrap;

          .form-group {
            width: 350px;
            margin-right: 12px;
            margin-bottom: 12px;

            input {
              height: 58px;
              width: 100%;
              padding-left: 2%;
              margin-bottom: 0;
            }
            .error-span {
              bottom: -26%;
            }
            textarea {
              width: 712px;
              height: 120px;
              padding-left: 2%;
              padding-top: 2%;
            }
            .textarea-span {
              bottom: -13%;
            }
          }

          .submit-button {
            top: 33%;
            right: 7%;
          }
        }
      }
    }
    .img-wrapper {
      margin-top: -55%;
      margin-left: -25%;

      .contacts-large {
        width: 346px;
        height: 468px;
      }
      .contacts-sm {
        width: 166px;
        height: 166px;
        right: -37%;
        top: 71%;
      }
    }
    .direction {
      display: flex;
    }
    .socials {
      display: flex;
      flex-direction: column;
      margin-top: -40rem;
      margin-left: 23%;

      img {
        margin-bottom: 40%;
        margin-right: 0;
      }

    }
  }
  .socials-lg {
    display: none;
  }
}

@media (min-width: 1512px) {

.title-wrap {
  margin-top:5%;
  .title p {
    font-family: Khand;
    font-size: 120px;
    line-height: 120px;
  }
  .info {
    margin-top: 3%;
    p {
      font-size: 40px;
      line-height: 60px;
      padding-right: 5%;
    }
  }
}
  .content-wrap {
    .form-wrap {
      margin-top: 0;

      .title p {
        font-size: 48px;
        line-height: 73px;
      }
      .form {
        margin-top: 2%;
        margin-bottom: 140px;
        form {
          width: 90%;

          .form-group {
            width: 340px;
            margin-right: 17px;
            margin-bottom: 17px;

            input {
              height: 60px;
              font-size: 24px;
              line-height: 36.7px;
            }
            .error-span {
              bottom: -32%;
            }
            .textarea-span {
              bottom: -10%;
            }

            textarea {
              width: 696px;
              height: 180px;
              font-size: 24px;
              line-height: 36.7px;
            }
          }

          .submit-button {
            top: 38%;
            right: 6%;
            img {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
    .img-wrapper {
      margin-top: -59%;
      margin-left: -20%;

      .contacts-large {
        width: 458px;
        height: 620px;
      }
      .contacts-sm {
        width: 220px;
        height: 220px;
        right: -28%;
        top: 62%;
      }
    }
    .socials {
      margin-top: -63rem;
      margin-left: 18.5%;
    }
  }
}

@media (min-width: 1920px) {
  .content-wrap {
    .form-wrap {
      .form {
        margin-bottom: 103px;
        form {
          .form-group {
            width: 420px;

            input {
              width: 100%;
              height: 60px;
            }
            textarea {
              width: 856px;
              height: 180px;
            }
          }

          .submit-button {
            top: 34%;
            right: 20%;
          }
        }
      }
    }
    .img-wrapper {
      .contacts-large {
        width: 472px;
        height: 639px;
      }
       .contacts-sm {
        width: 227px;
        height: 227px;
         top: 61%;
         right: -30%;
       }

    }
    .socials {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>