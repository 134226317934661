<template>
  <div class="container-fluid bg">
    <the-header class="padding" :dark_theme="dark_theme" />
    <div class="container block-1 p-0">
      <div class="title-block">
        <return-button />
        <div class="title">
          <p>
            Training App
          </p>
        </div>
      </div>

      <div class="text-wrap">
        <div>
          <p>
            Quis autem vel eum iure reprehenderit
            qui in ea voluptate velit esse quam nihil
            molestiae consequatu. Quis autem vel eum iure
            quam nihil molestiae consequatu
          </p>
        </div>
         <div>
            <p>
              Quis autem vel eum iure reprehenderit
              qui in ea voluptate velit esse quam nihil
              molestiae consequatu. Quis autem vel eum iure
              quam nihil molestiae consequatu
            </p>
        </div>
         <div>
          <p>
            Quis autem vel eum iure reprehenderit
            qui in ea voluptate velit esse quam nihil
            molestiae consequatu. Quis autem vel eum iure
            quam nihil molestiae consequatu
          </p>
        </div>
      </div>
    </div>
    <div class="container block-2">
      <div class="flex-block">
        <div class="title-link">
          <div class="title">
            <p> Let’s teach how to be the Best Employee </p>
          </div>
          <div class="title-adaptive">
            <p> Let’s teach <br> how to be the Best Employee </p>
          </div>
          <img src="@/assets/img/icons/green-link.svg" alt="" class="link">
        </div>

        <div class="img-wrap">
          <img src="@/assets/img/overview/img-block-2.png" class="mobile">
          <img src="@/assets/img/overview/img-block-2-1024.png" class="desktop">
        </div>
      </div>

      <div class="text-block">
        <div class="content">
          <p class="title"> New and methods
            in the app
          </p>
          <p class="text">
            Quis autem vel eum iure reprehenderit
            qui in ea voluptate velit esse quam nihil
            molestiae consequatu. Quis autem vel eum iure olestiae
          </p>
        </div>
      </div>
    </div>

    <div class="container block-3">
      <div class="flex-block">
        <div class="title">
          <p>
            Organize trainings
          </p>
        </div>
        <p class="custom-font">
          STEP BY STEP
        </p>
      </div>


      <div class="list-block">
        <div>
          <div class="item-list">
            <div class="d-flex align-items-start span">
              <span> 01.</span>
            </div>
            <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
          </div>
          <div class="item-list">
            <div class="d-flex align-items-start span">
              <span> 02.</span>
            </div>
            <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
          </div>
        </div>
        <div>
          <div class="item-list">
            <div class="d-flex align-items-start span">
              <span> 03.</span>
            </div>
            <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
          </div>
          <div class="item-list">
            <div class="d-flex align-items-start span">
              <span> 04.</span>
            </div>
            <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container block-4">
      <img src="@/assets/img/overview/img-block-3.png" alt="" class="img-phones">
      <div class="flex-block">
        <div class="title">
          <p> Assessments and Progress </p>
        </div>
        <div class="text-block">
          <p class="text">
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
          </p>
        </div>
      </div>
    </div>

    <div class="container block-5">
      <div class="block">
        <p class="custom-font"> gamification </p>
        <div class="title">
          <p>
            Earn points <br/>
            & get rewarded
          </p>
        </div>
      </div>

      <div class="img-wrap">
        <img src="@/assets/img/overview/img-5.png" alt="" class="img-lg">
        <img src="@/assets/img/menu/serv-col-sm.png" alt="" class="img-sm">
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import ReturnButton from "@/components/ReturnButton.vue";

export default {
  name: 'OverviewPage',
  components: {TheHeader, ReturnButton},
  data() {
    return {
      dark_theme: true,
      list: [
        {
          count: '01.',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate '
        },
         {
          count: '02.',
          text: 'Butem vel eum iure reprehenderit qui in ea voluptate '
        },
         {
          count: '03.',
          text: 'Sutem vel eum iure  qui in ea voluptate  '
        },
         {
          count: '04.',
          text: 'Atem vel eum iure reprehenderit qui in ea voluptate '
        },

      ]
    }
  },

}
</script>


<style scoped lang="scss">

.container-fluid {
  padding: 0;
  background-image: url("@/assets/img/overview/bg-mobile.svg");
  background-repeat: no-repeat;
  min-height: 844px;
  position: relative;
}
.padding {
  padding: 25px 20px 0 20px;
}

.block-1 {
  .img-bg {
    position: absolute;
    top: 0;

  }
  .title-block {
      margin-top: 30rem;
      padding-left: 20px;
  }
  .title {
    margin-top: 5%;
    position: relative;
    p {
      font-size: 72px;
      line-height: 72px;
      color: black;
      width: 40%;
    }
  }
  .text-wrap {
    margin-top: 8rem;
    padding: 0 20px 0 20px;
    p {
      font-size: 24px;
      line-height: 37px;
      margin-top: 5%;
    }
  }
}


.block-2 {
  margin-top: 20%;
  padding: 0 20px 0 20px;

  .title {
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }
  .link {
    width: 40px;
    height: 40px;
    margin-top: 5%;
  }
  .img-wrap {
    margin-top: 10%;
    .mobile {
      width: 350px;
    }
    .desktop {
      display: none;
    }
  }
  .text-block {

    margin-top: 10rem;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 40px;
          line-height: 48px;
          width: 75%;
          margin-left: -6%;
        }
        .text {
          font-size: 24px;
          line-height: 37px;
          width: 81%;
          margin-top: 5%;
        }
      }
  }
}

.block-3 {
  margin-top: 15%;
  padding: 0 20px 0 20px;

  .title {
    p {
      font-size: 48px;
      line-height: 48px;
    }
  }
  .custom-font {
    font-size: 24px;
    line-height: 37px;
    margin-top: 3%;
  }
  .list-block {
    margin-top:10%;

    .item-list {
      display: flex;
      border-bottom: 1px solid white;
      border-top: 1px solid white;
      padding: 5% 0 5% 0;

      span, p {
        font-family: Khand;
        font-size: 24px;
        font-weight: 400;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
      }
      .span {
        height: 100%;
        margin-right: 3%;
      }
      p {
        width: 93%;
      }
    }
  }
}

.block-4 {
  padding: 0 20px 0 20px;

  .img-phones {
    margin-top: 5rem;
    width: 346px;
    height: 425px;
  }
  .title {
    margin-top: 5rem;
    p {
      font-size: 48px;
      line-height: 48px;
    }
  }
  .text-block {

    .text {
      margin-top: 8%;
      font-size: 24px;
      line-height: 37px;
      width: 90%;
    }
  }
}

.block-5 {
  padding: 0 20px 0 20px;
  margin-top: 6rem;

  .custom-font {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0;
  }
  .title {
    margin-top: 5%;
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }

  .img-wrap {
    margin-top: 10%;
    padding-bottom: 10rem;
    position: relative;
    .img-lg {
      width: 350px;
    }
    .img-sm {
      width: 160px;
      height: 160px;
      position: absolute;
      right: 7%;
      bottom: 13%;
    }
  }
}

@media (min-width: 1024px) {
  .padding {
    padding: 40px 40px 0 40px;
  }
  .container-fluid {
    background-image: url("@/assets/img/overview/img-bg-1024.png");
  }
 .container {
   max-width: 100%;
 }

  .block-1 {
    margin: 0;

    .title-block {
      padding-left: 40px ;
      margin-top: 36rem;

      .title {
        margin-top: 2%;

        p {
          width: 30%;
          font-size: 100px;
          line-height: 100px;
        }
      }
    }
    .text-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 40px 0 40px;
      margin-top: 3rem;

      width: 100%;
      div {
        width: 46%;
        p {
          margin-top: 7%;
          font-size: 28px;
          line-height: 43px;
        }
      }
    }
  }

  .block-2 {
    padding: 0 40px 0 40px;

    .flex-block {
      display: flex;

      .title-link {
        .title {
          display: none;
        }
        .title-adaptive {
          p {
            font-size: 100px;
            line-height: 100px;
            width: 78%;
            position: relative;
            z-index: 2;
          }
        }
      }

      .img-wrap {
        .desktop {
          display: block;
          width: 480px;
          height: 357px;
          position: absolute;
          right: 40px;
        }

        .mobile {
          display: none;
        }
      }
    }

    .text-block {
      margin-top: 15rem;

      .content {
        flex-direction: row;
        align-items: start;
        justify-content: center;

        .title {
          font-size: 52px;
          line-height: 62px;
          margin-left: 0;
          width: 46%;
        }
        .text {
          font-size: 28px;
          line-height: 43px;
          margin-top: 0;
          width: 50%;
        }
      }
    }
  }

  .block-3 {
    padding: 0 40px 0 40px;

    .flex-block {
      display: flex;
      justify-content: space-between;

      .title p {
        font-size: 60px;
        line-height: 62px;
      }
      .custom-font {
        font-size: 26px;
        line-height: 40px;
      }
    }

    .list-block {
      display: flex;
      justify-content: space-between;
      margin-top: 5%;

      div {
        width: 48%;

        .item-list {
          width: 100%;
          padding: 7% 0 7% 0;

          .span {
            width: 10%;
          }
          p {
            width: 67%;
          }
        }
      }
    }
  }

  .block-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    margin-top: 10%;

    .img-phones {
      width: 452px;
      height: 555px;
    }
    .flex-block {
      width: 48%;
      .title p {
        font-size: 60px;
        line-height: 60px;
      }
      .text-block {
        .text {
          font-size: 28px;
          line-height: 43px;
          width: 94%;
        }
      }
    }
  }

  .block-5 {
    position: relative;
    .custom-font {
      font-size: 26px;
      line-height: 40px;
    }
    .title {
      margin-top: 3%;
      p {
        font-size: 100px;
        line-height: 100px;
      }
    }
    .block {
      margin-top: 25rem;
      position: relative;
      z-index: 2;
    }
    .img-wrap {
      .img-lg {
        width: 490px;
        height: 552px;
        position: absolute;
        top:-21rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .img-sm {
        width: 181px;
        height: 182px;
        right: 12%;
        bottom: 27rem;
      }
    }
  }
}

@media (min-width: 1366px) {
  .container-fluid {
    background-image: url("@/assets/img/overview/bg-1366.png");
  }

  .block-1 {
    .title-block {
      margin-top: 1%;

      .title {
        margin-top: 20rem;

        p {
          font-size: 90px;
          line-height: 90px;
          width: 20%;
        }
      }
    }
    .text-wrap {
      div {
        width: 31%;

        p {
          font-size: 24px;
          line-height: 37px;
        }
      }
    }
  }

  .block-2 {
    margin-top: 15%;

    .flex-block {
     .title-link  .title-adaptive  p {
        font-size: 90px;
        line-height: 90px;
        width: 65%;
      }
      .img-wrap {
        .desktop {
          width: 630px;
          height: 469px;
        }
      }
    }

    .text-block {
      margin-top: 29rem;
      .content {
        margin-left: 9%;

        .title {
          font-size: 52px;
          line-height: 52px;
          width: 32%;
        }
        .text {
          font-size: 24px;
          line-height: 37px;
          width: 40%;
        }
      }
    }
  }


  .block-3 {
    margin-top: 13%;


    .list-block {
      div {
        .item-list {
          padding: 5% 0 5% 0;
          width: 623px;

          .span {
            width: 5%;
          }
          p {
            width: 85%;
          }
        }
      }
    }
    .flex-block {
      .title p {
        font-size: 72px;
        line-height: 62px;
      }
    }
  }

  .block-4 {
    justify-content: space-evenly;

    .flex-block {
      width: 40%;

      .title p {
        width: 72%;
      }
      .text-block {
        .text {
          width: 105%;
        }
      }
    }
  }

  .block-5 {
    .block {
      margin-left: 250px;

      .title p {
        font-size: 90px;
        line-height: 90px;
      }
    }

    .img-wrap {
      .img-sm {
        width: 184px;
        height: 184px;
        right: 21%;
      }
    }
  }
}


@media (min-width: 1512px) {
  .container-fluid {
    background-image: url("@/assets/img/overview/bg-1512.png");
    background-size: 100%;
  }

  .block-1 {
    .title-block {
      .title {
        margin-top: 26rem;

        p {
          font-size: 160px;
          line-height: 140px;
        }
      }
    }
    .text-wrap {
      margin-top: 7rem;

      div p {
        font-size: 28px;
        line-height: 43px;
        width: 96%;
      }
    }
  }

  .block-2 {
    .flex-block {
      .title-link {
        .title-adaptive p {
          font-size: 120px;
          line-height: 120px;
        }
        .title p {
          font-size: 120px;
          line-height: 120px;
          width: 61%;
        }
        .link {
          width: 60px;
          height: 60px;
          margin-top: 3%;
        }
      }
      .img-wrap {
        .desktop {
          width: 715px;
          height: 533px;
        }
      }
    }
    .text-block {
      .content {
        justify-content: space-evenly;
        margin-left: 3%;

        .title {
          font-size: 64px;
          line-height: 72px;
        }
        .text {
          font-size: 28px;
          line-height: 43px;
        }
      }
    }
  }

  .block-3 {
    .flex-block {
      align-items: center;

      .title p {
        font-size: 80px;
        line-height: 122px;
      }

      .custom-font {
        font-size: 28px;
        line-height: 43px;
      }
    }
    .list-block {
      margin-top: 3%;

      div .item-list {
        width: 680px;
        p {
          font-size: 28px;
          line-height: 43px;
        }
        .span {
          margin-right: 1%;
        }
      }
    }
  }

  .block-4 {
    .img-phones {
      width: 532px;
      height: 653px;
    }
    .flex-block {
      .title p {
        font-size: 80px;
        line-height: 80px;
        width: 90%;
      }
      .text-block .text {
        width: 90%;
      }
    }
  }

  .block-5 {
    padding: 0;


    .block {
      margin-left: 200px;

      .title p {
        font-size: 120px;
        line-height: 120px;
        font-weight: 400;
      }
    }

    .img-wrap {
      .img-lg {
        top: -28rem;
        width: 640px;
        height: 720px;
      }
      .img-sm {
        width: 240px;
        height: 240px;
        bottom: 36rem;
        right: 16%;
      }
    }
  }
}

@media (min-width: 1920px) {
  .padding {
    padding: 40px 60px 0 60px;
  }

  .block-1 {
    .title-block {
      padding: 0 60px 0 60px;

      .title {
        margin-top: 41rem;
      }
    }
    .text-wrap {
      padding: 0 60px 0 60px;
    }
  }

  .block-2 {
    padding: 0 60px 0 60px;

    .flex-block {
      .img-wrap {
        .desktop {
          width: 900px;
          height: 671px;
          right: 60px;
          top: 31.4%;
        }
      }
      .title-link {
        .title p {
          width: 70%;
        }
      }

    }
    .text-block {
      margin-top: 35rem;
      .content {
        justify-content: center;
        margin-left: 0;

        .title {
          width: 25%;
        }
        .text {
          width: 29%;
        }
      }
    }
  }

  .block-3 {
    margin-top: 8%;
    padding: 0 60px 0 60px;

    .list-block {
      div.item-list {
        width: 870px;
      }
    }
  }

  .block-4 {
    padding: 0 60px 0 60px;

    .flex-block {
      .title {
        p {
          width: 77%;
        }
      }
      .text-block {
        .text {
          width: 68%;
        }
      }
    }
  }

  .block-5 {
    margin-top: 30rem;
    .block {
      margin-left: 380px;
    }
    .img-wrap {
      .img-lg {
        top: -29rem;
      }
      .img-sm {
        right: 24%;
      }
    }
  }
}
</style>