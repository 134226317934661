import { createStore } from "vuex"
import axios from "@/plugins/axios"

export default createStore({
  actions: {
    sendContacts(_context, contacts) {
      console.log(contacts)
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/contact-form',
          data: contacts,
          method: 'POST'

        }).then(res => {
          resolve(res.data)
        }, err => {
          reject(err.response)
        })
      })
    },
  },
})
