<template>
    <div class="button-block">
        <button @click="this.$router.push('/services-menu')">
          <img src="@/assets/img/icons/black-link.svg" alt="">
          <p>RETURN</p>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ReturnButton',
}
</script>

<style scoped lang="scss">
.button-block {
    position: relative;
    button {
        background: none;
        border: none;
        display: flex;
        align-items: center;

        p {
            font-family: Khand;
            font-size: 28px;
            font-weight: 400;
            line-height: 43px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 15%;
            color: black;

        }
    }
    
}
</style>