<template>
  <div class="container-fluid bg">
    <the-header class="padding" />
    <div class="container block-1 p-0">
      <div class="title-block">
        <div class="button-block">
          <button @click="this.$router.push('services-menu')">
            <img src="@/assets/img/icons/white-link.svg" alt="">
            <p>RETURN</p>
          </button>
        </div>
        <div class="title">
          <p>
            Consultancy
          </p>
        </div>
      </div>

      <div class="flex-block p-wrap">
        <div class="text-block">
          <p>
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
            Quis autem vel eum iure quam nihil molestiae consequatu.
          </p>
          <p class="margin">
            Quis autem vel eum iure quam nihil molestiae consequatu
          </p>
        </div>

        <div class="list-wrap">
          <div class="title">
            <p>
              What do we do
            </p>
          </div>

          <div class="list">
            <div class="item-list">
              <p> Hotel Management Services </p>
            </div>
            <div class="item-list">
              <p> Technical </p>
            </div>
            <div class="item-list">
              <p> Hotel Feasibility and Concept Development </p>
            </div>
            <div class="item-list">
              <p> Retail and Food & Beverage Hospitality </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block-2 container p-wrap ">
      <div class="title">
        <p>
          Hotel Management Service
        </p>
      </div>
      <div class="flex-block">
        <div class="item">
          <div class="text">
            <p> Quis autem vel eum iure <b> reprehenderit </b>  qui in ea voluptate velit esse quam nihil. </p>
          </div>
          <img src="@/assets/img/icons/green-link.svg" alt="" class="link">
        </div>
        <div class="item">
          <div class="text-block">
            <p>
              Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
              Quis autem vel eum iure  quam nihil molestiae consequatu
            </p>
            <p class="display">Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
              Quis autem vel eum iur
            </p>
          </div>
        </div>
      </div>

    </div>

    <div class="block-3 p-wrap container">
      <div class="img-wrap">
        <img src="@/assets/img/consultancy/img-lg-mobile-2.png" alt="" class="img-lg">
      </div>

      <div class="title">
        <p>
          Hotel Technical Service
        </p>
      </div>

      <div class="text-wrap">
        <p>
          Quis autem vel eum iure <b>reprehenderit</b>  qui in ea voluptate velit esse quam nihil molestiae consequatu. Quis autem vel eum.
        </p>
      </div>
      <img src="@/assets/img/icons/green-link.svg" alt="" class="link">

    </div>

    <div class="block-4 container p-wrap">
      <div class="title">
        <p>
          Hotel Feasibility and Concept Development
        </p>
      </div>
      <img src="@/assets/img/icons/green-link.svg" alt="" class="link">

      <div class="flex-block">
        <div class="item-block">
          <p>
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
            Quis autem vel eum iure  quam nihil molestiae consequatu
          </p>
        </div>
         <div class="item-block">
           <p>
             Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
             Quis autem vel eum iure  quam nihil molestiae consequatu
           </p>
        </div>
        <div class="item-block">
          <p>
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.
            Quis autem vel eum iure  quam nihil molestiae consequatu
          </p>
        </div>
      </div>
    </div>

    <div class="block-5 container p-wrap">
      <div class="title">
        <p>
          Retail and Food & Beverage Hospitality
        </p>
      </div>
      <img src="@/assets/img/icons/green-link.svg" alt="" class="link">
      <div class="text-wrap">
        <p>
          Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu.

        </p>
        <p> Quis autem iure  quam nihil molestiae consequatu. </p>
      </div>
      <img src="@/assets/img/consultancy/img-lg-5-mobile.png" class="img-lg">
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: 'ConsultancyPage',
  components: { TheHeader }
}

</script>

<style scoped lang="scss">
.bg {
  padding: 0;
  background-image: url("@/assets/img/consultancy/bg-mobile.png");
  background-repeat: no-repeat;
  min-height: 844px;
  background-size: 100%;
  position: relative;
}
.padding {
  padding: 25px 20px 0 20px;
}
.p-wrap {
  padding: 0 20px 0 20px;
}
.block-1 {
  .img-bg {
    position: absolute;
    top: 0;
  }
  .title-block {
    margin-top: 34rem;
    padding-left: 20px;
  }
  .button-block {
    button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      height: 3rem;

      p {
        font-family: Khand;
        font-size: 28px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: 0em;
        text-align: left;
      }
      img {
        margin-right: 10%;
      }
    }
  }
  .title {
    margin-top: 5%;
    position: relative;
    p {
      font-size: 72px;
      line-height: 72px;
      width: 40%;
    }
  }
  .text-wrap {
    margin-top: 8rem;
    padding: 0 20px 0 20px;
    p {
      font-size: 24px;
      line-height: 37px;
      margin-top: 5%;
    }
  }
  .flex-block {
    margin-top: 6rem;
    .text-block {
      p {
        font-size: 24px;
        line-height: 37px;
      }
      .margin {
        margin-top: 10%;
      }

    }
    .list-wrap {
      margin-top: 3rem;

      .title {
        p {
          font-size: 48px;
          line-height: 73px;
          width: 100%;
        }
      }

      .list {
        margin-top: 5%;
        .item-list {
          display: flex;
          border-bottom: 1px solid white;
          border-top: 1px solid white;
          padding: 5% 0 5% 0;
          align-items: center;

          p {
            font-size: 24px;
            line-height: 37px;
          }
        }
      }
    }
  }
}

.block-2 {
  margin-top: 5rem;

  .title {
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }
  .text {
    margin: 5% 0 5% 0;
    p {
      font-size: 24px;
      line-height: 37px;
    }
  }
  .flex-block {
    .item {
      .text-block {
        margin-top: 15%;
        p {
          font-size: 24px;
          line-height: 37px;
          margin-top: 8%;
        }
      }
    }
  }
}

.block-3 {
  position: relative;
  margin-top: 7rem;

  .img-wrap {
    .img-lg {
      width: 350px;
      height: 261px;
      top: 0;
    }
  }

  .title {
    margin-top: 10%;
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }
  .text-wrap {
    margin-top: 7%;
    p {
      font-size: 24px;
      line-height: 37px;
    }
  }
  .link {
    margin-top: 5%;
  }
}

.block-4 {
  margin-top: 20%;
  .title {
    margin-bottom: 5%;
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }

  .flex-block {
    margin-top: 10%;

    .item-block {
      margin-top: 7%;
      p {
        font-size: 24px;
        line-height: 37px;
      }
    }
  }
}

.block-5 {
  position: relative;
  margin-top: 6rem;
  .title {
    position: relative;
    z-index: 2;
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }
  .link {
    margin-top: 5%;
    z-index: 2;
    position: relative;
  }
  .text-wrap {
    position: relative;
    z-index: 2;
    margin-top: 19rem;
    padding-bottom: 5rem;

    p {
      font-size: 24px;
      line-height: 37px;
      margin-top: 5%;
    }
  }
  .img-lg {
    width: 350px;
    height: 375px;
    position: absolute;
    top: 18%;
  }
}


@media (min-width: 1024px) {
  .bg {
    background-image: url("@/assets/img/consultancy/bg-1024.png");
  }
  .padding {
    padding: 40px 40px 0 40px;
  }
  .p-wrap {
    padding: 0 40px 0 40px;
  }
  .container {
    max-width: 100%;
  }

  .block-1 {
    margin-left: 0;
    .title-block {
      margin-top: 39rem;
      padding-left: 40px;

      .title {
        margin-top: 3%;
        p {
          font-size: 100px;
          line-height: 100px;
        }
      }
    }
    .flex-block {
      .text-block {
        p {
          font-size: 28px;
          line-height: 43px;
        }
        .margin {
          margin-top: 3%;
        }
      }

      .list-wrap {
        .title {
          p {
            font-size: 60px;
            line-height: 62px;
          }
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item-list {
            width: 452px;
            padding: 2% 0 2% 0;

            p {
              font-size: 28px;
              line-height: 43px;
              padding-left: 5%;
            }
          }
        }
      }
    }
  }

  .block-2 {
    margin-top: 10rem;

    .title {
      p {
        font-size: 100px;
        line-height: 100px;
        width: 80%;
      }
    }
    .flex-block {
      .item {
        .text {
          p {
            font-size: 40px;
            line-height: 61px;
          }
        }
        .text-block {
          p {
            font-size: 28px;
            line-height: 43px;
            width: 77%;
            margin-top: -7%;
          }
          .display {
            display: none;
          }
        }
      }
    }
  }

  .block-3 {
    margin-top: 10rem;
    .img-wrap {
      .img-lg {
        width: 480px;
        height: 357px;
        right: 40px;
        top: -13%;
        position: absolute;
      }
    }
    .title {
      margin-top: 23%;

      p {
        font-size: 100px;
        line-height: 100px;
        width: 50%;
      }
    }
    .text-wrap {
      margin-top: 11%;
      p {
        font-size: 40px;
        line-height: 61px;
      }
    }
    .link {
      margin-top: 5%;
      margin-bottom: 5%;
    }
  }

  .block-4 {
    .title {
      p {
        font-size: 100px;
        line-height: 100px;
      }
    }

    .flex-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0;

      .item-block {
        width: 48%;
        p {
          font-size: 28px;
          line-height: 43px;
        }
      }
    }
  }

  .block-5 {
    .title {
      margin-top: 16%;
      p {
        font-size: 80px;
        line-height: 80px;
        width: 50%;
        margin-left: auto;
        margin-right: 0;
      }
    }
    .link {
      position: absolute;
      right: 45%;
      top: 33%;
      margin-top: 4%;
    }
    .img-lg {
      width: 452px;
      height: 481px;
      top: 1%;
    }
    .text-wrap {
      margin-top: 10rem;

      p {
        margin-left: auto;
        margin-right: 9%;
        width: 40%;
        margin-top: 10%;
      }
    }
  }
}

@media (min-width: 1366px) {
  .bg {
    background-image: url("@/assets/img/consultancy/bg-1366.png");
  }

  .block-1 {
    .title-block {
      margin-top: 24rem;

      .title {
        p {
          font-size: 90px;
          line-height: 90px;
        }
      }
    }

    .flex-block {
      display: flex;
      justify-content: space-between;

      .list-wrap {
        margin-top: 0;
        .title {
          margin-left: 11%;
          margin-top: 0;
        }
        .list {
          justify-content: end;
          .item-list {
            width: 600px;
          }
        }
      }
      .text-block {
        p {
          width: 38rem;
          margin-bottom: 5%;
        }
      }
    }
  }

  .block-2 {
    .title {
      p {
        font-size: 90px;
        line-height: 90px;
        width: 60%;
      }
    }
    .flex-block {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-top: 2%;

      .item {
        width: 50%;

        .text-block {
          margin-top: 0;
          display: flex;
          justify-content: end;
          p {
            margin-top: 0;
            width: 72%;
          }
        }
        .text {
          margin: 0;
          p {
            width: 93%;
          }
        }
        .link {
          margin-top: 5%;
        }
      }
    }
  }

  .block-3 {
    .title {
      margin-top: 21%;
      p {
        font-size: 90px;
        line-height: 90px;
      }
    }
    .img-wrap {
      .img-lg {
        width: 630px;
        height: 469px;
        right: 40px;
        top: -28%;
      }
    }
    .text-wrap {
      margin-top: 1%;
      p {
        font-size: 36px;
        line-height: 55px;
        width: 44%;
      }
    }
  }

  .block-4 {
    margin-top: 13%;
    .title {
      p {
        font-size: 90px;
        line-height: 90px;
        width: 60%;
      }
    }
    .link {
      margin-top: -5%;
    }
    .flex-block {
      margin-top: -5%;

      .item-block {
        width: 31%;
      }
    }
  }

  .block-5 {
    .title {
      p {
        font-size: 90px;
        line-height: 90px;
        margin-left: 0;
        width: 60%;
      }
    }
    .link {
      left: 40px;
      top: 19%;
    }
    .img-lg {
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 518px;
      height: 552px;
    }
    .text-wrap {
      margin-top: 16%;
      p {
        margin-right: 0%;
        margin-top: 2%;
        width: 27%;
      }
    }
  }
}

@media (min-width: 1512px) {
  .bg {
    background-image: url("@/assets/img/consultancy/bg-1512.png");
  }

  .block-1  {
    .title-block {
      margin-top: 3rem;
      .title {
        margin-top: 32rem;
        p {
          font-size: 160px;
          line-height: 140px;
        }
      }
    }

    .flex-block {
      margin-top: 9rem;

      .text-block {
        margin-top: 2%;
        p {
          width: 92%;
        }
      }

      .list-wrap {
        .title {
          margin-left: 22%;
          p {
            font-size: 80px;
            line-height: 122px;
          }
        }
        .list {
          .item-list {
            width: 706px;
          }
        }
      }
    }
  }

  .block-2 {
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
      }
    }

    .flex-block {
      .item {
        .text {
          p {
            width: 87%;
          }
        }
        .link {
          width: 60px;
          height: 60px;
        }
        .text-block {
          p {
            width: 94%;
          }
        }
      }
    }
  }

  .block-3 {
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
      }
    }
    .text-wrap {
      p {
        font-size: 40px;
        line-height: 61px;
      }
    }
    .link {
      width: 60px;
      height: 60px;
    }
    .img-wrap {
      .img-lg {
        width: 715px;
        height: 533px;
        top: -20%;
      }
    }
  }

  .block-4 {
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
        width: 65%;
      }
    }
    .link {
      width: 60px;
      height: 60px;
      margin-top: -1%;
    }
    .flex-block {
      margin-top: 0;

      .item-block {
        p {
          font-size: 28px;
          line-height: 43px;
        }
      }
    }
  }

  .block-5 {
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
        width: 80%;
      }
    }
    .link {
      width: 60px;
      height: 60px;
      margin-top: 9%;
    }
    .img-lg {
      width: 620px;
      height: 660px;
      top: 48%;
    }
    .text-wrap {
      margin-top: 15%;
      p {
        font-size: 28px;
        line-height: 43px;
        width: 25%;
      }
    }
  }
}


@media (min-width: 1920px) {
  .padding {
    padding: 40px 60px 0 60px;
  }
  .p-wrap {
    padding: 0 60px 0 60px;
  }

  .block-1 {
    .title-block {
      margin-top: 1rem;
      padding-left: 60px;

      .title {
        margin-top: 50rem;
      }
    }
    .flex-block {
      margin-top: 12rem;

      .text-block {
        width: 50%;
        p {
          width: 90%;
        }
      }
      .list-wrap {
        width: 50%;

        .title {
          margin-left: 10%;
        }
        .list {
          .item-list {
            width: 800px;
            padding: 3% 0 3% 0;
          }
        }
      }
    }
  }

  .block-2 {
    margin-top: 15rem;

    .flex-block {
      margin-top: 2%;
    }
  }

  .block-3 {
    .img-wrap {
      .img-lg {
        width: 902px;
        height: 673px;
        right: 60px;
      }
    }
  }

  .block-5 {
    .img-lg {
      width: 676px;
      height: 720px;
    }

    .title p {
      width: 60%;
    }
  }
}
</style>