
<template>
  <div class="d-flex justify-content-between align-items-center header">
    <p class="title"> TribalResorts </p>
    <img src="@/assets/img/icons/close.svg"  @click="this.$router.push('/')">
  </div>
</template>


<script>
export default {
  name: 'HeaderNavComponent'
}
</script>


<style scoped lang="scss">
.header {
  padding-top: 25px;

  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 55px;
  }
}


@media (min-width: 1024px) {
  .header {
    padding-top: 40px;
    .title {
      font-size: 40px;
      line-height: 61.16px;
    }
    img {
      width: 48px;
    }
  }
}


@media (min-width: 1366px) {
  .header {
    width: 100%;
  }
}
</style>