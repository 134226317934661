import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { defineRule } from 'vee-validate'
import { email, alpha, numeric } from '@vee-validate/rules';


defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required';
  }
  return true;
});


defineRule('email', email);
defineRule('alpha', alpha);
defineRule('numeric', numeric);


import './assets/css/app.scss'

createApp(App).use(router).use(store).mount('#app')