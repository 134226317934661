<template>
  <div class="container-fluid bg">
    <HeaderNavComponent />
    <div class="menu col-xl-6">
      <div class="container p-0">
        <div class="menu-wrap">
          <div class="title">
            <p>menu</p>
          </div>
          <div class="list">
            <ul>
             <router-link to="/">
               <li>Home</li>
             </router-link>

              <router-link to="/services">
                <li> Services </li>
              </router-link>
              <img
                  src="@/assets/img/icons/menu-link.svg"
                  class="menu-link"
                  @click="this.$router.push('/services-menu')"
              />

              <router-link to="/about">
                <li>About</li>
              </router-link>

              <router-link to="/contacts">
                <li>Contact</li>
              </router-link>

            </ul>
          </div>
        </div>
        <div class="socials justify-content-center align-items-center">
          <img src="@/assets/img/socials-icons/facebook.svg" alt="">
          <img src="@/assets/img/socials-icons/inst.svg" alt="">
          <img src="@/assets/img/socials-icons/twitter.svg" alt="">
        </div>
      </div>
    </div>
    <div class="col-xl-6 d-flex img-container">
      <img src="@/assets/img/menu/menu-col.png" alt="" class="resorts" v-if="!isServicesMenu">
      <img src="@/assets/img/menu/menu-col-sm.png" alt="" class="sm-resorts" v-if="!isServicesMenu">
      <img src="@/assets/img/menu/serv-col.png" alt="" class="resorts" v-if="isServicesMenu">
      <img src="@/assets/img/menu/serv-col-sm.png" alt="" class="sm-resorts" v-if="isServicesMenu">



      <div class="socials  flex-column" :class="{'active_socials': this.isServicesMenu}">
        <img src="@/assets/img/socials-icons/facebook.svg" alt="" class="s-img">
        <img src="@/assets/img/socials-icons/inst.svg" alt="">
        <img src="@/assets/img/socials-icons/twitter.svg" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import HeaderNavComponent from "@/components/HeaderNav.vue";

export default {
  name: 'NavigationPage',
  components: {HeaderNavComponent},
  data() {
    return {
      isServicesMenu: false
    }
  }
}
</script>

<style scoped lang="scss">
.resorts {
  display: none;
}
.sm-resorts {
  display: none;
}
.header {
  padding-top: 25px;

  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 55px;
  }
}

.container {
  .title {
    margin-top: 80px;
    p {
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 5px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .list {
    margin-top: 15%;
    position: relative;
    ul {
      padding: 0;
      a {
        text-decoration: none;
      }
      li {
        color: #666666;
        list-style: none;
        font-family: Khand;
        height: 60px;
        font-size: 48px;
        font-weight: 400;
        line-height: 60px;
        margin-bottom: 5%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      li:hover {
        color: #FFFFFF;
      }
      .menu-link {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 18%;
        top: 33%;
      }
      .menu-link:hover  {
        content: url("@/assets/img/icons/menu-link-active.svg");
      }
    }
  }
  .display {
    display: none;
  }

  .services-menu {
    .button-wrap {
      margin-top: 12%;
      button {
        background: none;
        border: none;
        color: white;
        width: 100%;
        font-family: Khand;
        font-size: 28px;
        line-height: 43px;

        img {
          margin-right: 5%;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .socials {
    display: flex;
    margin-top: 10rem;
    padding-bottom:21.5%;

    img {
      margin: 5%;
      cursor: pointer;
    }
  }
  .active_socials {
    margin-top: 73px;
  }

}
.img-container {
  .socials {
    display: none;
  }
}


@media (min-width: 1024px) {
  .header {
    padding-top: 40px;
    .title {
      font-size: 40px;
      line-height: 61.16px;
    }
    img {
      width: 48px;
    }
  }

  .container {
    .title {
      margin-top: 18%;
    }
    .list {
      margin-top: 6%;
      ul {
        li {
          font-size: 60px;
          line-height: 60px;
          margin-bottom: 7%;
        }
        .menu-link {
          width: 32px;
          height: 32px;
          right: 35%;
          top: 32%;
        }
      }
    }
    .socials {
      margin-top: 18rem;
      padding-bottom: 20.5%;

      img {
        width: 44px;
        margin: 2%;
      }
    }
    .active_socials {
      margin-top: 177px;
    }
    .services-menu {
      .button-wrap {
        margin-top: 5%;
        button img {
          margin-right: 2%;
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .container-fluid {
    display: flex;
    flex-wrap: wrap;

    .header {
      width: 100%;
    }
    .menu {
      .container {
        .socials {
          display: none;
        }

        .menu-wrap, .services-menu{
          .title {
            margin-top: 10%;
          }
          .list {
            ul {
              li {
                font-size: 48px;
                line-height: 60px;
                margin-bottom: 4%;
              }
              .menu-link {
                width: 24px;
                height: 24px;
                right: 32%;
                top: 32%;
              }
            }
          }
        }
      }
    }

    .img-container {
      padding-bottom: 5%;
      position: relative;

      .resorts {
        display: block;
        width: 475px;
        height: 535px;
      }
      .sm-resorts {
        display: block;
        position: absolute;
        width: 156px;
        height: 156px;
        top: 20%;
        right: 10%;
      }
      .socials {
        display: flex;
        margin-left: 7%;
        margin-top: 54%;
        img {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (min-width: 1512px) {
  .container-fluid {
    .menu .container {
      .menu-wrap, .services-menu {
        .title {
          margin-top: 15%;
          margin-bottom: 9%;
        }
        .list {
          ul {
            li {
              font-size: 60px;
              line-height: 60px;
              margin-bottom: 7%;
            }
            .menu-link {
              width: 32px;
              height: 32px;
              right: 30%;
            }
          }
        }
      }
    }
    .img-container {
      margin-top: 61px;
      margin-bottom: 130px;
      .resorts {
        width: 550px;
        height: 620px;
      }
      .sm-resorts {
        width: 180px;
        height: 180px;
        top: 26%;
        right: 6%;
      }
      .socials {
        margin-left: 5%;
        margin-top: 61%;
      }
    }
  }
}

@media (min-width: 1920px) {
  .container-fluid {
    .menu .container {
      .menu-wrap, .services-menu {
        .list {
          ul {
            .menu-link {
              right: 34%;
            }
          }
        }
      }
    }
    .img-container {
      margin-bottom: 64px;
      .socials {
        margin-top: 52%;
      }
      .resorts {
        width: 577px;
        height: 650px ;
      }
      .sm-resorts {
        width: 189px;
        height: 189px;
        top: 19%;
        right: 18%;
      }
    }
  }

}

</style>