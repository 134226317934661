<template>
  <div class="container-fluid bg">
    <the-header />
    <div class="container p-0 block-1">
        <div class="title">
          <p>
            Focusing on people needs
          </p>
        </div>
      <div class="img-wrap">
        <img src="@/assets/img/about/block01-lg.png" class="img-lg">
        <img src="@/assets/img/about/block01-sm.png"  class="img-sm">
        <img src="@/assets/img/about/adaptive-mid-img.png"  class="img-mid">
      </div>
      <div class="link">
        <img src="@/assets/img/icons/link.svg" alt="">
      </div>
    </div>

    <div class="container p-0 block-2 d-flex flex-column justify-content-center align-items-center">
        <p class="title"> Our approach </p>
        <p class="text">
          Quis autem vel eum iure reprehenderit qui
          in ea voluptate velit esse quam nihil molestiae
          consequatu. Quis autem vel eum iure olestiae
        </p>
    </div>
    
    <div class="container p-0 block-3">
      <div class="wrapper">
        <div class="img-wrap">
          <img src="@/assets/img/about/block03-lg.png" alt="" class="img-lg">
          <img src="@/assets/img/about/block03-sm.png" alt=""  class="img-sm">
        </div>
        <div class="list-wrap">
          <ul>
            <li class="b-top d-flex align-items-center"> Value #1 </li>
            <li class="b-top b-bottom d-flex align-items-center"> Value #2 </li>
            <li class="b-bottom d-flex align-items-center"> Value #3 </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container p-0 block-4">
      <div class="title">
        <p>Get to know us</p>
      </div>
      <div class="text-list">
        <div
            class="text-item"
            v-for="(item, index) in textList"
            :key="index"
        >
          <p class="title"> {{ item.title }} </p>
          <p class="text"> {{item.text}} </p>
        </div>
      </div>
    </div>

    <div class="container p-0 block-5">
      <div class="title">
        <p>
          Are you ready <br> to start growing your business with us?
        </p>
      </div>
      <img src="@/assets/img/about/block05-img.png" alt="" class="img-lg">
      <img src="@/assets/img/icons/submit.svg" alt="" class="submit">
      <p class="text"> CONTACT US RIGHT NOW </p>
    </div>

  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: 'AboutPage',
  components: {TheHeader},
  data() {
    return {
      textList: [
        {
          title: 'Our team',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu. Quis autem vel eum iure  quam nihil molestiae consequatu'
        },
        {
          title: 'Our passion',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu. Quis autem vel eum iure  quam nihil molestiae consequatu'
        },
        {
          title: 'Our team',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu. Quis autem vel eum iure  quam nihil molestiae consequatu'
        },

      ]
    }
  }
}
</script>


<style scoped  lang="scss">
    .block-1 {
      margin-top: 15%;
      .title {
        p {
          font-size: 72px;
          line-height: 72px;
        }
      }
      .img-wrap {
        margin-top: 10%;
        position: relative;

        .img-lg {
          width: 234px;
          height: 375px;
          margin-right: 0;
          margin-left: 33%;
        }
        .img-sm {
          width: 156px;
          height: 156px;
          position: absolute;
          bottom: -30%;
          right: 10%;
        }
        .img-mid {
          display: none;
        }
      }
      .link {
        margin-top: 5%;
        padding-bottom: 16%;
      }
    }

    .block-2 {
      margin-top: 10rem;

      .title {
        font-size: 60px;
        line-height: 60px;
        text-align: center;
      }
      .text {
        margin-top: 10%;
        font-size: 24px;
        line-height: 37px;
        text-align: center;
        width: 85%;
      }
    }

    .block-3 {
      .wrapper {
        .img-wrap {
          position: relative;
          margin-top: 7rem;

          .img-lg {
            width: 350px;
            height: 534px;
          }
           .img-sm {
            width: 160px;
            height: 160px;
             position: absolute;
             left: 27%;
             bottom: -15%;
          }

        }
        .list-wrap {
          margin-top: 9rem;
          ul {
            padding-left: 0;
            list-style: none;
            li {
              font-family: Khand;
              font-size: 40px;
              line-height: 48px;
              color: #FFFFFF;
              height: 6rem;
            }
            .b-top  {
              border-top: 1px solid white;
            }
            .b-bottom, {
              border-bottom: 1px solid white;
            }
          }
        }
      }
    }

    .block-4 {
      margin-top: 8rem;
      .title {
        p {
          font-size: 60px;
          line-height: 60px;
        }
      }
      .text-list {
        margin-top: 12%;

        .text-item {
          margin-bottom: 12%;
          .title {
            font-size: 48px;
            line-height: 52px;
          }
          .text {
            margin-top: 5%;
            font-size: 24px;
            line-height: 37px;
          }
        }
      }
    }

    .block-5 {
      margin-top: 8rem;
      position: relative;
      .title {
        p {
          font-size: 60px;
          line-height: 60px;
        }
      }
      .img-lg {
        margin-top: 25%;
        width: 350px;
        height:372px;
      }
      .submit {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 0%;
        top: 29%;
      }
      .text {
        margin-top: 15%;
        font-size: 24px;
        line-height: 37px;
        padding-bottom: 10rem;
      }
    }

    @media (min-width: 1024px) {
      .block-1 {
        margin-top: 7%;
        .title {
          p {
            font-size: 100px;
            line-height: 100px;
            width: 60%;
          }
        }
        .img-wrap {
          .img-lg {
            width: 448px;
            height: 720px;
            margin-left: 52.5%;
          }
          .img-sm {
            width: 322px;
            height: 320px;
            bottom: 12%;
            right: 43%;
          }
        }
      }

      .block-2 {
        margin-top: 5%;
        .title {
          font-size: 100px;
          line-height: 100px;
        }
        .text {
          margin-top: 3%;
          font-size: 28px;
          line-height: 43px;
          width: 55%;
        }
      }

      .block-3 {
        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10rem;

          .img-wrap {
            margin-top: 0;
            .img-lg {
              width: 480px;
              height: 718px;
            }
            .img-sm {
              width: 200px;
              height: 200px;
              left: 75%;
              bottom: -14%;
            }

          }
          .list-wrap {
            margin-top: 0;
            ul li {
              width: 424px;
              font-size: 48px;
              line-height: 100px;
              padding-left: 5%;
              height: 9rem;
            }
          }
        }
      }

      .block-4 {
        margin-top: 16rem;
        .title {
          p {
            font-size: 100px;
            line-height: 100px;
          }
        }
        .text-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 5%;

          .text-item {
            width: 48%;
            margin-bottom: 6%;
            .title {
              font-size: 52px;
              line-height: 60px;
            }
            .text {
              font-size: 28px;
              line-height: 43px;
              width: 94%;
            }
          }
        }
      }

      .block-5 {
        .title {
          position: relative;
          p {
            font-size: 100px;
            line-height: 100px;
            width: 80%;
          }
        }
        .submit {
          top:34%;
        }
        .img-lg {
          width: 518px;
          height: 552px;
          margin-left: 41%;
          margin-top: -15%;
        }
        .text {
          margin-top: -26px;
        }
      }
    }


    @media (min-width: 1366px) {
      .container {
        max-width: 100%;
      }
      .block-1 {
        margin-top: 3%;
        position: relative;

        .title {
          p {
            font-size: 90px;
            line-height: 90px;
            width: 40%;
          }
        }
        .img-wrap {
          position: initial;
          .img-lg {
            width: 306px;
            height: 489px;
            position: absolute;
            margin: 0;
            right: 11%;
            top: -15.5%;
          }
          .img-sm {
            width: 206px;
            height: 205px;
            right: 0;
          }
          .img-mid {
            display: block;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 230px;
            height: 430px;
          }
        }
        .link {
          padding-bottom: 0;
          margin-top: 25%;
        }
      }

      .block-2 {
        margin-top: 15%;

        .title {
          font-size: 90px;
          line-height: 90px;
        }
        .text {
          font-size: 24px;
          line-height: 37px;
          width: 40%;
        }
      }

      .block-3 {
        .wrapper {
          .img-wrap {
            .img-lg {
              width: 630px;
              height: 940px;
            }
            .img-sm {
              width: 260px;
              height: 260px;
            }
          }
          .list-wrap {
            ul li {
              width: 616px;
            }
          }
        }
      }

      .block-4 {
        margin-top: 25%;

        .title p {
          font-size: 80px;
          line-height: 80px;
        }
        .text-list {
          justify-content: space-around;
          .text-item {
            width: 33%;
            .title {
              font-size: 48px;
              line-height: 52px;
            }
            .text {
              font-size: 24px;
              line-height: 37px;
              width: 90%;
            }
          }
        }
      }

      .block-5 {
        .title p {
          font-size: 90px;
          line-height: 90px;
          width: 70%;
        }
        .submit {
          top: 36%;
        }
        .img-lg {
          margin-left: 30%;
          margin-top: -10%;
        }
        .text {
          text-align: end;
          width: 88%;
        }
      }
    }
    
    @media (min-width: 1512px) {
      .block-1 {
        margin-top: 5%;

        .title p {
          font-size: 120px;
          line-height: 120px;
          width: 50%;
        }
        .img-wrap {
          .img-mid {
            width: 279px;
            height: 522px;
            top: 80%;
            left: 48%;
          }
          .img-lg {
            width: 372px;
            height: 594px;
            top: 3.5%;
          }
          .img-sm {
            width: 250px;
            height: 249px;
            bottom: -15%;
            right: -25px;
          }
        }
      }

      .block-2 {
        margin-top: 30%;

        .title {
          font-size: 120px;
          line-height: 120px;
        }
        .text {
          font-size: 28px;
          line-height: 43px;
          width: 35%;
        }
      }

      .block-3 {
        margin-top: 15%;

        .wrapper {
          .img-wrap {
            .img-lg {
              width: 670px;
              height: 1000px;
            }
            .img-sm {
              width: 280px;
              height: 280px;
            }
          }
          .list-wrap {
            ul li {
              width: 642px;
              font-size: 64px;
              line-height: 100px;
            }
          }
        }
      }

      .block-4 {
        .title {
          p {
            font-size: 120px;
            line-height: 120px;
          }
        }
        .text-list {
          .text-item {
            .title {
              font-size: 58px;
              line-height: 72px;
            }
            .text {
              font-size: 28px;
              line-height: 43px;
              width: 91%;
            }
          }
        }
      }

      .block-5 {
        .title p {
          font-size: 120px;
          line-height: 120px;
        }
        .img-lg {
          width: 620px;
          height: 660px;
          margin-top: -13%;
        }
        .text {
          font-size: 28px;
          line-height: 43px;
          margin-top: -30px;
          width: 95%;
        }
        .submit {
          top: 40%;
        }
      }
    }
    
    @media (min-width: 1920px) {
      .block-1 {
        .title p {
          width: 36%;
        }
        .img-wrap {
          .img-mid {
            width: 322px;
            height: 601px;
            top: 60%;
            left: 50%;
          }
          .img-lg {
            width: 429px;
            height: 684px;
            top: -18%;
          }
          .img-sm {
            width: 288px;
            height: 287px;
            bottom: 7%;
            right: 0px;
          }
        }
      }

      .block-2 {
        margin-top: 20%;
        .title {
          font-size: 120px;
          line-height: 120px;
        }
        .text {
          font-size: 28px;
          line-height: 43px;
          width: 29%;
        }
      }

      .block-3 {
        .wrapper {
          .img-wrap {
            .img-lg {
              width: 840px;
              height: 1255px;
            }
            .img-sm{
              width: 300px;
              height: 300px;
            }
          }

          .list-wrap {
            ul li {
              width: 900px;
            }
          }
        }
      }

      .block-5 {
        .img-lg {
          width: 658px;
          height: 700px;
          margin-top: -10%;
        }
        .text {
          width: 83%;
        }
      }
    }
</style>