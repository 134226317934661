<template>
  <div  v-if="toast.show" >
    <div class="container p-0 d-flex justify-content-center align-items-center flex-column" :class="{'success_bg': toast.isSuccess}">
      <p class="antonio-font"> {{ toast.message}}</p>
      <img :src="require(`@/assets/img/icons/${toast.img_url}.png`)" alt="" class="loader" :class="{'success': toast.isSuccess}">
    </div>
  </div>
</template>

<script>
export  default {
  name: 'ToastComponent',
  props: ['toast'],

}
</script>


<style scoped lang="scss">
.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #303030;
  width: 316px;
  height: 741px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 60px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0.05em;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }
  .loader {
    width: 145px;
    height: 145px;
    object-fit: cover;
    animation: spin 4s linear infinite;
    margin-top: 8%;
  }
  .success {
    animation: none;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

.success_bg {
  background-color: #303030;

  p {
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    color: white;
    text-transform: none;
    width: 90%;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 60%;
    height: 70%;


    p {
      width: 80%;
      font-size: 80px;
      line-height: 100px;
    }
  }
}

@media (min-width: 1366px) {
  .container {
    width: 60%;
    height: 50%;


    .loader {
      margin-top: 0;
    }
    p {
      width: 80%;
      font-size: 50px;
      line-height: 60px;
    }
  }
}

@media (min-width: 1512px) {
  .container {
    width: 1595px;
    height: 850px;

    p {
      font-size: 120px;
      font-weight: 700;
      line-height: 155px;
      letter-spacing: 0.05em;
    }
    .loader {
      width: 303px;
      height: 303px;
    }
  }

  .success_bg {
    p {
      margin-top: 10%;
      font-size: 100px;
      line-height: 129px;
      text-align: center;
    }

    .success {
      width: 250px;
      height: 250px;
    }
  }

}
</style>
