<template>
  <div class="d-flex justify-content-between align-items-center header">
    <p class="title" :class="{'dark-title': this.dark_theme}"> TribalResorts </p>
    <img 
      src="@/assets/img/icons/menu.svg" 
      @click="this.$router.push('/menu')" 
      :class="{'menu-none': this.dark_theme}"
    >

    <img
      src="@/assets/img/icons/menu-black.svg"
      @click="this.$router.push('/menu')"
      :class="{'dark-icon': this.dark_theme}"
      v-if="this.dark_theme"
    >
  </div>
</template>


<script>
export default {
  name: 'TheHeader',
  props: ['dark_theme']
}

</script>

<style scoped lang="scss">
.header {
  padding-top: 25px;
  z-index: 5;
  position: relative;
}
.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 55px;
}
.dark-title {
  color: black;
}

.menu-none {
  display: none;
}

@media (min-width: 1024px) {
  .title {
    font-size: 40px;
    line-height: 61px;
  }

  .header {
    padding-top: 40px;
    img {
      width: 48px;
    }
  }
}
</style>