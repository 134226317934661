<template>
  <div class="container-fluid bg">
    <the-header class="padding" />
    <div class="container block-1 p-0">
      <div class="title-block">
        <div class="button-block">
          <button @click="this.$router.push('/services-menu')">
            <img src="@/assets/img/icons/white-link.svg" alt="">
            <p>RETURN</p>
          </button>
        </div>
        <div class="title">
          <p>
            Online Services
          </p>
        </div>
      </div>
    </div>
    <div class="container block-2 p-wrap">
      <div class="title">
        <p> Have a hotel? <br> Let’s create a website for you </p>
      </div>
      <img src="@/assets/img/icons/green-link.svg" alt="" class="link">

      <div class="img-wrap">
        <img src="@/assets/img/online-service/block-1-center-img.png" alt="" class="img-lg">
        <img src="@/assets/img/online-service/bl-1-phone.png" alt="" class="img-sm">
      </div>

      <div class="text-wrap">
        <p> TELL THE WORLD ABOUT YOURSELF </p>
      </div>
    </div>

    <div class="container block-3 p-0">
      <div class="scroll-wrap">
        <div class="scroll">
          <div class="item-block" v-for="(item, index) in scrollBlocks" :key="index">
            <p class="title"> {{ item.title }} </p>
            <p class="text"> {{ item.text }} </p>
          </div>
        </div>
      </div>

      <div class="text-wrap">
        <div class="title">
          <p> Connected to your bookings </p>
        </div>
        <div class="text-block">
          <p class="text">
            Quis autem vel eum
            iure reprehenderit qui in ea voluptate
            velit esse quam nihil molestiae consequatu. Quis autem vel eum iure olestiae
          </p>
        </div>

      </div>
      <hr class="hr">
    </div>

    <div class="container block-4">
      <div class="title">
        <p> SOCIAL MEDIA & STRATEGY  </p>
      </div>

      <div class="swiper-wrap">
        <swiper
            :grabCursor="true"
            :effect="'creative'"
            :creativeEffect="{
            prev: {
              shadow: true,
              translate: ['-120%', 0, -500],
            },
            next: {
              shadow: true,
              translate: ['120%', 0, -500],
            },
          }"
            :modules="modules"
            class="mySwiper2"
        >
          <swiper-slide>
            <img src="@/assets/img/online-service/swiper-img-1.png" alt="" class="swiper-img">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/img/online-service/swiper-img-1.png" alt="" class="swiper-img">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/img/online-service/swiper-img-1.png" alt="" class="swiper-img">
          </swiper-slide>
        </swiper>
      </div>
      <div class="search-wrap">
        <img src="@/assets/img/online-service/search.svg" alt="" class="search">
      </div>


    </div>

    <div class="container block-5 p-wrap">
      <div class="d-flex flex-block">
        <div class="item-block">
          <div class="title">
            <p>Keywords and Content creation</p>
          </div>

          <div class="list">
            <div class="item-list">
              <div class="d-flex align-items-start span">
                <span> 01.</span>
              </div>
              <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
            </div>
            <div class="item-list">
              <div class="d-flex align-items-start span">
                <span> 02.</span>
              </div>
              <p> Butem vel eum iure reprehenderit qui in ea voluptate  </p>
            </div>

          </div>
        </div>

        <div class="item-block">
          <div class="title">
            <p> The value of each leas through  SEA </p>
          </div>

          <div class="list">
            <div class="item-list">
              <div class="d-flex align-items-start span">
                <span> 01.</span>
              </div>
              <p> Quis autem vel eum iure reprehenderit qui in ea voluptate  </p>
            </div>
            <div class="item-list">
              <div class="d-flex align-items-start span">
                <span> 02.</span>
              </div>
              <p> Butem vel eum iure reprehenderit qui in ea voluptate  </p>
            </div>

          </div>
        </div>
      </div>

    </div>


  </div>
</template>
<script>
import TheHeader from "@/components/TheHeader.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCreative } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-creative';

export default {
  name: 'OnlineService',
  components: {
    TheHeader,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [EffectCreative],
    };
  },
  data() {
    return {
      scrollBlocks: [
        {
          title: 'For hotel',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu'
        },
        {
          title: 'For hotel',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu'
        },
        {
          title: 'For hotel',
          text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu'
        }
      ],
      swiperOptions: {
        spaceBetween: 1 // Adjust the space as per your requirement
        // Other Swiper options here
      }
    }
  }
}
</script>

<style scoped lang="scss">


.bg {
  padding: 0;
  background-image: url("@/assets/img/online-service/bg-mobile.png");
  background-repeat: no-repeat;
  min-height: 844px;
  position: relative;
}
.padding {
  padding: 25px 20px 0 20px;
}
.block-1 {
  .img-bg {
    position: absolute;
    top: 0;
  }
  .title-block {
    margin-top: 30rem;
    padding-left: 20px;
  }
  .button-block {
    button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      height: 3rem;

      p {
        font-family: Khand;
        font-size: 28px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: 0em;
        text-align: left;
      }
      img {
        margin-right: 10%;
      }
    }
  }
  .title {
    margin-top: 5%;
    position: relative;
    p {
      font-size: 72px;
      line-height: 72px;
      width: 40%;
    }
  }
  .text-wrap {
    margin-top: 8rem;
    padding: 0 20px 0 20px;
    p {
      font-size: 24px;
      line-height: 37px;
      margin-top: 5%;
    }
  }
}
.p-wrap {
  padding: 0 20px 0 20px;
}
.block-2 {
  margin-top: 25%;

  .title {
    p {
      font-size: 60px;
      line-height: 60px;
    }
  }
  .link {
    margin-top: 5%;
  }

  .img-wrap {
    margin-top: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    .img-lg {
      width: 342px;
      height: 364px;
    }
    .img-sm {
      width: 251px;
      height: 398px;
      position: absolute;
      top: 26.5rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text-wrap {
    display: none;
  }
}

.block-3  {
  margin-top: 20rem;

  .scroll-wrap {
    .scroll::-webkit-scrollbar {
      display: none;
    }
    .scroll {
      display: flex;
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;
      scrollbar-width: none;
      padding-bottom: 20%;
      padding-left: 20px;

      .item-block {
        min-width: 334px;
        min-height: 280px;
        background-color:#303030;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5%;
        margin-right: 5%;

        .title {
          font-size: 36px;
          font-weight: 500;
          line-height: 55px;
        }
        .text {
          font-size: 20px;
          line-height: 31px;
        }
      }
    }
  }
  .text-wrap {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
    margin-top: 10%;

    .title {
      font-size: 40px;
      line-height: 61px;
    }
    .text {
      font-size: 24px;
      line-height: 37px;
      width: 75%;
    }
  }
}

.hr {
  width: 100%;
  margin-top: 20%;
  border: 1px solid #303030;
}

.block-4 {
  margin-top: 5rem;
  padding: 0 20px 0 20px;

  .title {
    p {
      font-size: 100px;
      font-weight: 500;
      line-height: 90px;
      color: #303030;
    }
  }
  .mySwiper2 {
    .swiper-slide {
      display: flex;
      justify-content: center;

      .swiper-img {
        width: 265px;
        height: 518px;
      }
    }
  }
  .search-wrap {
    display: flex;
    justify-content: center;

    .search {
      width: 350px;
      padding-top: 7rem;
    }
  }
}


.block-5 {
  margin-top: 10%;
  .flex-block {
    flex-direction: column;
      .item-block {
       margin-bottom: 20%;
        .title {
          p {
            font-size: 40px;
            line-height: 48px;
          }
        }
        .list {
          margin-top: 8%;
          .item-list {
            display: flex;
            border-bottom: 1px solid white;
            border-top: 1px solid white;
            padding: 5% 0 5% 0;

            span, p {
              font-family: Khand;
              font-size: 24px;
              font-weight: 400;
              line-height: 37px;
              letter-spacing: 0em;
              text-align: left;
              color: white;
            }

            .span {
              height: 100%;
              margin-right: 3%;
            }

            p {
              width: 93%;
            }
          }
        }
      }
  }
}

@media (min-width: 1024px) {
  .bg {
    background-image: url("@/assets/img/online-service/bg-1024.png");
    background-size: 100%;
  }
  .padding {
    padding: 40px 40px 0 40px;
  }

  .block-1 {
    margin-left: 0;
    .title-block {
      margin-top: 31rem;
      padding-left: 40px;

      .title {
        p {
          font-size: 100px;
          line-height: 100px;
        }
      }
    }
  }

  .block-2 {
    padding:  0 40px 0 40px;
    margin-top: 18%;

    .title {
      position: relative;
      z-index: 2;
      p  {
        font-size: 100px;
        line-height: 100px;
        width: 93%;

      }
    }
    .img-wrap {
      margin-top: -26.4%;
      .img-lg {
        width: 455px;
        height: 484px;
      }
      .img-sm {
        top: 51%;
        left: 10%;
        transform: rotateZ(-6deg);
      }
    }

    .text-wrap {
      display: flex;
      justify-content: end;
      margin-top: -7.5%;

      p {
        font-size: 26px;
        line-height: 40px;
        text-transform: uppercase;
        width: 20%;
      }
    }
  }
.container {
  max-width: 100%;
}
  .block-3 {
    margin-left: 0;
    margin-right: 0;

    .scroll-wrap {
      .scroll {
        padding-left: 40px;

        .item-block {
          min-width: 480px;
          height: 400px;
          margin-right: 2%;

          .title {
            font-size: 48px;
            font-weight: 500;
            line-height: 73px;
          }
          .text {
            font-size: 28px;
            line-height: 43px;
            margin-top: 5%;
          }
        }
      }
    }

    .text-wrap {
      flex-direction: row;
      align-items: start;
      justify-content: center;
      margin-top: -5%;

      .title {
        width: 38%;
        p {
          font-size: 52px;
          line-height: 62px;
        }
      }
      .text-block {
        width: 41%;

        .text {
          width: 100%;
          font-size: 28px;
          line-height: 43px;
        }
      }
    }
    .hr {
      margin-top: 9%;
    }
  }

  .block-4 {
    .title {
      p {
        font-size: 140px;
        font-weight: 500;
        line-height: 120px;
        width: 66%;
      }
    }

    .swiper-wrap {
      margin-top: -5%;
      .swiper {
        .swiper-img {
          width: 338px;
          height: 660px;
        }
      }
    }
    .search-wrap {
      .search {
        width: 600px;
      }
    }
  }

  .block-5 {
    padding: 0 40px 0 40px;

    .flex-block {
      flex-direction: row;
      justify-content: space-between;
      .item-block {
        width: 99%;
        .list {
          .item-list {
            width: 452px;
            font-size: 24px;
            line-height: 37px;
          }
        }
        .title {
          p {
            font-size: 52px;
            line-height: 60px;
          }
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .bg {
    background-image: url("@/assets/img/online-service/bg-1366.png");
  }

  .block-1 {
    .title-block {
      margin-top: 1rem;
      .title {
        margin-top: 22%;
        p {
          font-size: 90px;
          line-height: 90px;
          width: 26%;
        }
      }
    }
  }

  .block-2 {
    margin-top: 16%;
    .link {
      margin-top: 2%;
    }
    .title {
      p {
        font-size: 90px;
        line-height: 90px;
        width: 60%;
      }
    }

    .img-wrap {
      margin-top: -17.4%;

      .img-lg {
        width: 517px;
        height: 550px;
      }
      .img-sm {
        left: 18%;
      }
    }
    .text-wrap {
      margin-top: -30px;
      p {
        width: 26%;
      }
    }
  }

  .block-3 {
    .scroll-wrap {
      .scroll {
        padding-bottom: 5%;
      }
    }
    .text-wrap {
      margin-top: 10%;
    }
  }

  .block-4 {
    margin-top: 8rem;
    .title {
      padding-left: 40px;
      p {
        font-size: 160px;
        font-weight: 500;
        line-height: 140px;
        width: 64%;
      }
    }

    .swiper-wrap {
      .swiper {
        .swiper-img {
          width: 307px;
          height: 600px;
        }
      }
    }

    .search-wrap {
      margin-top: 5%;
    }
  }

  .block-5 {
    .flex-block {
      .item-block {
        .title {
          p {
            width: 64%;
          }
        }
        .list {
          .item-list {
            width: 623px;
          }
        }
      }
    }
  }
}


@media (min-width: 1512px) {
  .bg {
    background-image: url("@/assets/img/online-service/bg-1512.png");
  }

  .block-1 {
    .title-block {
      .title {
        margin-top: 22%;
        p {
          font-size: 160px;
          line-height: 140px;
        }
      }
    }
  }

  .block-2 {
    margin-top: 12%;
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
        width: 65%;
      }
    }

    .img-wrap {
      .img-lg {
        width: 620px;
        height: 660px;
      }
      .img-sm {
        width: 310px;
        height: 492px;
        top: 45%;
      }
    }
  }

  .block-3 {
    .scroll-wrap {
      .scroll {
        .item-block {
          min-width: 660px;
          height: 460px;

          .title {
            font-size: 52px;
            font-weight: 500;
            line-height: 80px;
          }

          .text {
            font-size: 28px;
            line-height: 43px;
            width: 86%;
          }
        }
      }
    }
    .text-wrap {
      .title {
        width: 36%;
        p {
          font-size: 64px;
          line-height: 72px;
        }
      }
      .text {
        font-size: 28px;
        line-height: 43px;
        width: 85%;
      }
    }
  }

  .block-4 {
    .title {
      p {
        font-size: 200px;
        font-weight: 500;
        line-height: 160px;
      }
    }
    .swiper-wrap {
      .swiper {
        .swiper-img {
          width: 338px;
          height: 660px;
        }
      }
    }
    .search-wrap {
      .search {
        width: 820px;
      }
    }
  }

  .block-5 {
    .flex-block {
      justify-content: space-between;
      .item-block {
        .title {
          p {
            font-size: 64px;
            line-height: 72px;
            width: 71%;
          }
        }
        .list {
          .item-list {
            width: 680px;
            font-size: 28px;
            line-height: 43px;
            padding-left: 2%;

            p {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1920px) {
  .padding {
    padding: 40px 60px 0 60px;
  }

  .block-1 {
    .title-block {
      padding-left: 60px;

      .title {
        margin-top: 24%;
        p {
          font-size: 160px;
          line-height: 140px;
        }
      }
    }
  }

  .block-2 {
    padding-left: 60px;
    .title {
      p {
        width: 56%;
      }
    }
    .img-wrap {
      margin-top: -14.4%;

      .img-lg {
        width: 675px;
        height: 720px;
      }
      .img-sm {
        left: 23%;
      }
    }
  }


  .block-3 {
    .scroll-wrap {
      .scroll {
        padding-left: 60px;
        .item-block {
          height: 480px;
          margin-right: 60px;
        }
      }
    }
    .text-wrap {
      .title, .text-block {
        width: 27%;
      }
    }
  }

  .block-4 {
    .title {
      p {
        width: 50%;
      }
    }
  }

  .block-5 {
    .flex-block {
      .item-block {
        .list {
          .item-list {
            width: 870px;
          }
        }
      }
    }
  }
}
</style>