<template>
  <div class="container-fluid bg">
    <the-header class="padding"/>
    <div class="container  block-1">
      <div class="title">
        <p>Our Software</p>
      </div>
      <div class="adaptive-flex">
        <div class="text">
          <p>
            Quis autem vel eum iure reprehenderit qui
            in ea voluptate velit esse quam nihil molestiae
            consequatu. Quis autem vel eum iure quam nihil molestiae consequatu.
          </p>
          <p> Quis autem vel eum iure  quam nihil molestiae consequatu</p>
        </div>
        <div class="list-block">
          <div class="title-list">
            <p>
              What do we have for you
            </p>
          </div>

          <div class="list">
            <div class="item-list one">
              <p> Training App </p>
            </div>
            <div class="item-list two">
              <p> Great Stay App </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class=" container block-2">
      <div class="title">
        <p> TRAINING APP </p>
      </div>
      <div class="img-link">
        <img src="@/assets/img/our-software/block-2-img.png" alt="" class="img-lg">
        <img src="@/assets/img/icons/circle-link.svg" alt="" class="circle-link">
        <div class="adaptive-block">
          <p>
            LET’S TEACH HOW TO BE THE BEST EMPLOYEE
          </p>
        </div>
      </div>

      <div class="title-content">
        <p>Details</p>
      </div>

      <div class="content">
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
      </div>
      <hr class="end-hr">

    </div>

    <div class=" block-2 block-3">
      <div class="title">
        <p>
          THE GREAT STAY
        </p>
      </div>

      <div class="img-link">
        <img src="@/assets/img/our-software/block-3-img.png" alt="" class="img-lg">
        <img src="@/assets/img/icons/circle-link.svg" alt="" class="circle-link">

        <div class="adaptive-block">
          <p>
            LET’S TEACH HOW TO BE THE BEST EMPLOYEE
          </p>
        </div>
      </div>

      <div class="title-content">
        <p>Details</p>
      </div>

      <div class="content">
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam
          nihil molestiae consequatu. Quis autem
          vel eum iure  quam nihil molestiae consequatu
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: 'OurSoftwarePage',
  components: {TheHeader},
}
</script>


<style scoped lang="scss">
.container-fluid {
  padding: 0;
  background-image: url("@/assets/img/our-software/block-1-bg-mobile.png");
  background-repeat: no-repeat;
  min-height: 844px;
}

.padding {
  padding-right: 20px;
  padding-left: 20px;
}

.block-1 {
  padding-right: 20px;
  padding-left: 20px;
  .title {
    margin-top: 33.5rem;
    p {
      font-size: 72px;
      line-height: 72px;
      width: 50%;
    }
  }

  .text {
    margin-top: 6rem;

    p {
      margin-bottom: 5%;
      font-size: 24px;
      line-height: 37px;
    }
  }
  .list-block {
    .title-list  p {
      font-size: 48px;
      line-height: 54px;
      margin-top: 20%;
      width: 85%;
    }

    .list {
      margin-top: 5%;
      .item-list {
        border-top: 1px solid white;
        height: 4.5rem;
        display: flex;
        align-items: center;

        p {
          font-size: 24px;
          line-height: 37px;
        }
      }
      .two {
        border-bottom: 1px solid white;
      }
    }
  }
}

.img-link {
  position: relative;
  .adaptive-block {
    display: none;
  }
  .circle-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.block-2 {
  margin-top: 30%;
  padding: 0 20px 0 20px;
  .title {
    p {
      font-size: 90px;
      line-height: 80px;
    }
  }

  .title-content {
    margin-top: 15%;
    p {
      font-size: 48px;
      line-height: 54px;
    }
  }
  .content {
    margin-top: 5%;
    p {
      font-size: 24px;
      line-height: 37px;
      padding-bottom: 5%;
    }
  }

  .end-hr {
    width: 100%;
    border-color: white;
    margin-top: 20%;
  }
}

.block-3 {
  padding: 0 20px 0 20px;

  .title {
    p {
      font-size: 90px;
      line-height: 80px;
    }
  }

  .img-link {
    margin-top: 5%;
  }
}


@media (min-width: 1024px) {
 .container-fluid {
   background-image: url("@/assets/img/our-software/block-1-bg-1024.png");
   min-height: 100%;
 }
  .padding {
    padding-right: 40px;
    padding-left: 40px;
  }

  .block-1 {
    margin: 0;
    padding-right: 40px;
    padding-left: 40px;
    max-width: 100%;

    .title {
      margin-top: 38rem;
      p {
        font-size: 100px;
        line-height: 100px;
        width: 40%;
      }
    }

    .text p {
      font-size: 28px;
      line-height: 43px;
    }

    .list-block {
      .title-list {
        p {
          font-size: 60px;
          line-height: 62px;
        }
      }
      .list {
        display: flex;
        justify-content: space-between;
        .item-list {
          width: 452px;
          border-bottom: 1px solid white;

          p {
            font-size: 28px;
            line-height: 43px;
            padding-left: 5%;
          }
        }
      }
    }
  }

  .block-2 {
    margin-top: 15%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding: 0 40px 0 40px;
    .title {
      position: relative;
      z-index: 2;

      p {
        font-size: 140px;
        line-height: 120px;
        width: 53%;
      }
    }
    .img-link {
      display: flex;
      justify-content: center;
      position: relative;

        .img-lg {
          width: 480px;
          height: 510px;
          margin-top: -19%;
        }
      .circle-link {
        transform: none;
        top: 64%;
        left: 79%;
      }
      .adaptive-block {
        display: block;
        position: absolute;
        top: -57%;
        right: 0;
        width: 20%;
        p {
          font-family: Khand;
          font-size: 26px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;

        }
      }
    }
    .title-content {
      p {
        font-size: 60px;
        line-height: 92px;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1%;
      p {
        font-size: 28px;
        line-height: 43px;
        width: 45%;
      }
    }
    .end-hr {
      margin-top: 5%;
    }
  }
  .block-3 {
    .img-link {
      .img-lg {
        margin-top: -23%;
      }
      .adaptive-block {
        top: -77%;
      }
    }
  }
}

@media (min-width: 1366px) {
  .container-fluid {
    background-image: url("@/assets/img/our-software/block-1-bg-1366.png");
    background-size: 100%;
  }
  .block-1 {
    .title {
      margin-top: 29rem;
    }
    .adaptive-flex {
      display: flex;
      .text , .list-block {
        width: 50%;
      }
      .text {
        margin-top: 9rem;
        p {
          font-size: 28px;
          line-height: 43px;
          width: 91%;
        }
      }
      .list-block {
        .list {
          display: block;
          .item-list {
            width: 600px;
          }
        }
      }
    }
  }

  .block-2 {
    .title {
      p {
        font-size: 160px;
        line-height: 140px;
      }
    }
    .img-link {
      .img-lg {
        width: 528px;
        height: 561px;
        margin-top: -17%;
      }
      .adaptive-block {
        top: -66%;
        right: 6%;
      }
      .circle-link {
        top: 66%;
        left: 74%;
      }
    }

    .title-content {
      margin-top: 10%;
      p {
        font-size: 60px;
        line-height: 52px;
      }
    }
    .content {
      margin-top: 5%;
      p {
        font-size: 24px;
        line-height: 37px;
        width: 31%;
      }
    }
  }

  .block-3 {
    .img-link {
      .img-lg {
        margin-top: -22%;
      }
      .adaptive-block {
        top: -18rem;

      }
      .circle-link {
        top: 58%;
        left: 74%;
      }
    }
  }
}

@media (min-width: 1512px) {
  .container-fluid {
    background-image: url("@/assets/img/our-software/block-1-bg-1512.png");
  }

  .block-1 {
    .title {
      p {
        font-size: 160px;
        line-height: 140px;
      }
    }
    .adaptive-flex {
      .text {
        margin-top: 10rem;
        p {
          width: 74%;
        }
      }
      .list-block {
        .title-list p {
          font-size: 80px;
          line-height: 122px;
          width: 100%;
        }
        .list {
          .item-list {
            width: 706px;
            height: 5.5rem;
          }
        }
      }
    }
  }

  .block-2 {
    .title p {
      font-size: 200px;
      line-height: 160px;
    }
    .img-link {
      .img-lg {
        width: 620px;
        height: 660px;
      }
      .adaptive-block {
        width: 19%;
        top: -61%;

        p {
          line-height: 43px;
        }
      }
      .circle-link {
        width: 160px;
        height: 160px;
        top: 63%;
        left: 75%;
      }
    }

    .title-content p {
      font-size: 80px;
      line-height: 122px;
    }
    .content {
      margin-top: 2%;
      p {
        font-size: 28px;
        line-height: 43px;
        width: 30%;
      }
    }
  }

  .block-3 {
    .img-link {
      .adaptive-block {
        top: -95%
      }
      .circle-link {
        top: 56%;
      }
    }
  }
}

@media (min-width: 1920px) {
  .container-fluid {
    background-image: url("@/assets/img/our-software/block-1-bg-1920.png");
  }
  .padding {
    padding-left: 60px;
    padding-right: 60px;
  }
  .block-1 {
    padding: 0 60px 0 60px;
    .title {
      margin-top: 32rem;
      p {
        width: 30%;
      }
    }
    .adaptive-flex {
      justify-content: space-between;
      .text {
        margin-top: 12.5rem;
        p {
          width: 87%;
        }
      }
      .list-block {
        width: max-content;
        .list {
          .item-list {
            width: 800px;
          }
        }
      }
    }
  }

  .block-2 {
    padding: 0 60px 0 60px;
    .title {
      p {
        width: 40%;
      }
    }
    .img-link {
      .img-lg {
        width: 676px;
        height: 720px;
        margin-top: -13%;
      }
      .adaptive-block {
        width: 15%;
        top: -52%;
        right: 13%;
      }
      .circle-link {
        top: 69%;
        left: 72%;
      }
    }
  }

  .block-3 {
    .img-link {
      .adaptive-block {
        top: -50%;
      }
    }
  }
}
</style>