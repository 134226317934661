<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.container-fluid {
  padding:  0 20px 0 20px;
}
</style>
