<template>
  <div class="container-fluid bg">
    <the-header class="padding"/>

    <div class="container block-1">
      <div class="title">
        <p>
          What can we do for your business
        </p>
      </div>
      <div class="img-wrap">
        <img src="@/assets/img/services/img-lg-1.png" alt="" class="img-lg">
        <img src="@/assets/img/services/img-sm-1.png" alt="" class="img-sm">
        <img src="@/assets/img/services/img-mid-1.png" alt="" class="img-mid">
      </div>
      
      <div class="link-wrap">
        <div @click="this.$router.push('/menu')">
          <img src="@/assets/img/icons/link.svg" alt="">
          <p class="custom-font"> Our main services </p>
        </div>
      </div>
    </div>

    <div class="container p-0 block-2">
      <div class="title">
        <p>
          Services we provide
        </p>
      </div>

      <div class="link-blocks">
        <div class="item-block" v-for="(item, index) in linkBlocksContent" :key="index">
          <div class="img-wrap">
            <img :src="require(`@/assets/img/services/${item.img_src}.png`)" alt="" class="img-lg">
            <img src="@/assets/img/icons/circle-link.svg" alt="" class="link">
          </div>
          <div class="title">
            <p> {{ item.title }}</p>
          </div>
          <p class="custom-font">
            {{ item.content }}
          </p>

          <div class="child-block">
            <div class="title-child">
              <p> {{ item.title }} </p>
            </div>
            <p class="text">{{ item.content }}</p>

            <router-link :to=item.route>
              <img src="@/assets/img/icons/circle-link.svg" alt="" class="link">
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: 'ServicesPage',
  components: {TheHeader},
  data() {
    return {
      linkBlocksContent: [
        {
          title: 'Our Software',
          img_src: 'item-img-1',
          content: 'discover our digital solutions for your business',
          route: 'our-software'
        },
         {
          title: 'Online Service',
          img_src: 'item-img-2',
          content: 'discover what can we do for you in a digital world',
           route: 'online-service'
        },
        {
          title: 'Consultancy',
          img_src: 'item-img-3',
          content: 'get all the insights from our experts',
          route: 'consultancy'
        },
      ]
    }
  }
}
</script>


<style scoped lang="scss">

.block-1 {
  margin-top: 15%;
  position: relative;
  padding: 0;

  .title {
    position: relative;
    z-index: 2;
    p {
      font-size: 72px;
      line-height: 72px;
    }
  }
  .img-wrap {
    .img-lg {
      width: 286px;
      height: 320px;
      position: absolute;
      right: -20px;
      top: 28%;
    }
    .img-sm {
      width: 103px;
      height: 143px;
      z-index: 2;
      position: relative;
      margin-top:6rem;
    }
    .img-mid {
      display: none;
    }
  }

  .link-wrap {
    margin-top: 6rem;
    p {
      font-size: 24px;
      line-height: 37px;
      margin-top: 6%;
    }
  }
}

.block-2 {
  margin-top: 10%;
  .title {
    p {
      font-size: 48px;
      line-height: 73px;
      font-weight: 500;
    }
  }
  .link-blocks {
    .item-block {
      padding-bottom: 20%;
      .child-block {
        display: none;
      }
      .img-wrap {
        margin-top: 5%;
        position: relative;

        .link {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
        .img-lg {
          width: 350px;
          height: 432px;
        }
      }
      .title {
        margin-top: -10%;
        z-index: 2;
        position: relative;
        p {
          width: 50%;
          font-size: 60px;
          line-height: 60px;
        }
      }
      .custom-font {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 3px;
        margin-top: 5%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .container-fluid {
    overflow: hidden;
  }
  .block-1 {
    margin-top: 7%;

    .title {
      p {
        font-size: 100px;
        line-height: 100px;
        width: 50%;
      }
    }
    .img-wrap {
      .img-lg {
        width: 640px;
        height: 720px;
        right: -17%;
        top: 8.5%;
      }
      .img-sm {
        width: 232px;
        height: 320px;
        margin-top: 6rem;
        margin-left: 30%;
      }
    }
  }

  .block-2 {
    margin-top: 15%;
    .title p {
      font-size: 60px;
      line-height: 62px;
    }
    .link-blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2%;

      .item-block {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 7%;
        .title {
          margin-top: -7%;
        }
        .img-wrap  {
          .img-lg {
            width: 452px;
            height: 558px;
          }
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .padding {
    padding-right: 40px;
    padding-left: 40px;
  }
  .container-fluid {
    padding: 0;
  }
  .container {
    max-width: 100%;
  }
  .block-1 {
    margin-top: 5%;
    padding:  0 40px 0 40px;
    .title p {
      font-size: 90px;
      line-height: 90px;
      position: relative;
      z-index: 2;
      width: 46%;
    }

    .img-wrap {
      .img-mid {
        display: block;
        width: 207px;
        height: 207px;
        position: absolute;
        right: 7%;
        top: 7%;
      }
      .img-lg {
        width: 551px;
        height: 620px;
        top: 46%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .img-sm {
        width: 200px;
        height: 276px;
        margin-top: 0;
        margin-left: 19%;
      }

    }
    .link-wrap {
      display: flex;
      justify-content: end;
      margin-top: -10%;
      width: 99%;

      p {
        font-size: 24px;
        line-height: 37px;

      }
    }
  }

  .block-2 {
    margin-top: 17%;
    .title {
      p {
        line-height: 92px;
        padding-left: 40px;
      }
    }

    .link-blocks {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      padding-left: 40px;
      justify-content: normal;
      flex-wrap: nowrap;
      padding-bottom: 10rem;

      .item-block {
        width: 39%;
        margin-right: 40px;
        position: relative;

        .img-wrap {
          .img-lg {
            width: 485px;
            height: 600px;
          }
          .link {
            display: none;
          }
        }
        .title {
          margin-top: -30%;
          height: 120px;
          display: flex;
          align-items: end;
          p {
            padding-left: 20px;
          }
        }
        .custom-font {
          display: none;
        }
      }

      .item-block:hover  .child-block {
        display: block;
        background-color: rgba(38, 38, 38, 0.9);
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        height: 400px;

        .title-child {
          margin-top: 15%;
           p {
             font-size: 60px;
             line-height: 60px;
             padding-left: 10%;
           }
        }
        .text {
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 2px;
          text-transform: uppercase;
          padding-left: 10%;
          padding-right: 10%;
          margin-top: 10%;
        }
        .link {
          margin-left: 35%;
          margin-top: 28%;
        }
      }
      .item-block:hover .title {
        display: none;
      }
    }
  }
}

@media (min-width: 1512px) {
  .block-1 {
    margin-top: 5%;
    .title p {
      font-size: 120px;
      line-height: 120px;
      width: 51%;
    }
    .link-wrap {
      margin-top: -10px;
      width: 95.6%;

      .custom-font {
        margin-top: 10%;
      }
    }
    .img-wrap {
      .img-lg {
        width: 640px;
        height: 720px;
        top: 47%;
      }
      .img-sm {
        width: 232px;
        height: 320px;
        margin-top: 3%;
        margin-left: 15%;
      }
      .img-mid {
        width: 240px;
        height: 240px;
        right: 7%;
        top: 26%;
      }
    }
  }

  .block-2 {
    .title p {
      font-size: 80px;
      line-height: 122px;
    }
    .link-blocks {
      margin-top: -1%;

      .item-block {
        margin-right: 146px;
        .img-wrap {
          .img-lg {
            width: 680px;
            height: 840px;
          }
        }
        .title p {
          font-size: 80px;
          line-height: 80px;
          padding-left: 40px;
        }
      }
      .item-block:hover .child-block {
        width: 420px;
        height: 500px;
        left: 59%;

        .title-child {
          font-size: 80px;
          line-height: 80px;
          width: 50%;
          margin-top: 5%;
          p {
            padding: 40px;
          }
        }
        .text {
          font-size: 24px;
          line-height: 40px;
          margin-top: 0;
        }
        .link {
          width: 160px;
          height: 160px;
          position: absolute;
          bottom: -12%;
          left: -3%;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .padding {
    padding-right: 60px;
    padding-left: 60px;
  }
  .block-1 {
    padding: 0 60px 0 60px;

    .title p {
      width: 44%;
    }
    .link-wrap {
      margin-top: -3%;
      width: 97.6%;
    }
    .img-wrap {
      .img-lg {
        width: 770px;
        height: 866px;
        left: 55%;
        top: 42%;
      }
      .img-sm {
        width: 278px;
        height: 383px;
        margin-top: 1%;
        margin-left: 22%;
      }
      .img-mid {
        width: 289px;
        height: 288px;
        right: 3%;
        top: 15%;
      }
    }
  }

  .block-2 {
    .link-blocks {
      .item-block {
        margin-right: 40px;
        .img-wrap {
          .img-lg {
            width: 664px;
            height: 820px;
          }
        }
        .title {
          margin-top: -24%;
        }

      }
      .item-block:hover .child-block {
        left: 50%;
      }
    }
  }
}

</style>