<template>
  <div class="container-fluid bg">
    <the-header />
    <div class="container p-0 block-1">
      <div class="title">
        <p>
          Creating more stable
          business
        </p>
      </div>
      <img src="@/assets/img/home/bed.png" class="bed">
      <img src="@/assets/img/home/suitcase.png" class="suitcase">
    </div>

    <div class="container p-0 block-2">
      <div class="link-wrap d-flex align-items-end flex-column">
        <img src="@/assets/img/icons/link.svg" class="link">
        <p> Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae </p>
      </div>
    </div>

    <div class="container block-3">
      <div class="title">
        <p> Small subtitle or slogan is here </p>
      </div>
      <div class="content">
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam nihil
          molestiae consequatu. Quis autem vel eum iure
          olestiae
        </p>
      </div>
    </div>

    <div class="container p-0 block-4">
      <div class="title">
        <p> We can help you with your business so you can just relax </p>
      </div>
      <div class="flex">
        <img src="@/assets/img/home/relax.png" alt="">
        <div class="list">
          <div class="list-item borders">
            <span> 01. </span>
            <p> Software for your business </p>
          </div>
          <div class="list-item">
            <span> 02. </span>
            <p> All the online services you need </p>
          </div>
          <div class="list-item borders">
            <span> 03. </span>
            <p> Consultancy to make your business thrive </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container block-5 p-0">
      <div class="content d-flex justify-content-center align-items-center flex-column">
        <p> Let’s make <br> your business stable </p>
        <img src="@/assets/img/home/stable.png" alt="">
      </div>
    </div>

    <div class="container p-0 block-6">
      <div class="title">
        <p>Let us tell you
          what we do</p>
      </div>
      <div class="text">
        <p class="first-p">
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam nihil
          molestiae consequatu. Quis autem vel eum iure
          quam nihil molestiae consequatu
        </p>
        <p>
          Quis autem vel eum iure reprehenderit
          qui in ea voluptate velit esse quam nihil
          molestiae consequatu. Quis autem vel eum iure
          quam nihil molestiae consequatu
        </p>

      </div>
      <hr class="adaptive-hr">
    </div>
    <hr class="adaptive-hr">
    <div class="container p-0 block-7">
      <p class="mission"> Mission </p>
      <div class="title">
        <p>
          Your success is their joy
        </p>
      </div>
      <div class="img-wrap">
        <img src="@/assets/img/home/joy.png" alt="" class="joy">
        <img src="@/assets/img/home/joy-small.png" alt="" class="joy-small">
      </div>
    </div>
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: 'HomePage',
  components: {TheHeader}
}

</script>

<style scoped lang="scss">
.block-1 {
  position: relative;
  z-index: 1;
  min-height: 33.6rem;

  .title {
    margin-top: 10%;
    z-index: 2;
    position: relative;

    p {
      font-size: 72px;
      font-weight: 400;
      line-height: 72px;
    }
  }
  .bed {
    width: 256px;
    height: 424px;
    position: absolute;
    right: -20px;
    top: 19.5%;

  }
  .suitcase {
    width: 171px;
    position: absolute;
    top: 19rem;
    z-index: 2;
  }
}

.block-2 {
  .link-wrap {
    width: 100%;
    margin-left: auto;
    text-align: right;
    margin-top: 15%;

    img {
      width: 40px;
    }
    p {
      margin-top: 5%;
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
    }
  }
}

.block-3 {
  padding-left: 28px;
  padding-right: 0;
  margin-top: 40%;
  padding-bottom: 40%;

  .title {
    p {
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
    }
  }
  .content {
    margin-top: 8%;
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 37px;
      width: 90%;
    }
  }
}

.block-4 {
  .title {
    font-size: 60px;
    font-weight: 400;
    line-height: 60px;
  }
  img {
    width: 350px;
    margin-top: 5%;
  }
  .list {
    margin-top: 10%;
    .borders {
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }
    .list-item {
      display: flex;
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 37px;
        padding: 4% 0 4%  0;
      }
      span {
        padding-right: 4%;
        padding-top: 4%;
        font-family: Khand;
        font-size: 24px;
        font-weight: 400;
        line-height: 37px;
        color: $font-color;
      }
    }
  }
}

.block-5 {
  margin-top: 35%;
  .content {
    p {
      font-size: 60px;
      font-weight: 400;
      line-height: 60px;
      width: 90%;
      text-align: center;
    }
    img {
      width: 294px;
      margin-top: 20%;
    }
  }
}

.block-6 {
  margin-top: 40%;

  .title {
    margin-bottom: 5%;
    p {
      font-size: 48px;
      font-weight: 400;
      line-height: 52px;
      width: 90%;
    }
  }
  .text {
    p {
      margin-top: 5%;
      font-size: 24px;
      font-weight: 400;
      line-height: 37px;
    }
  }

}

.adaptive-hr {
  display: none;
}
.block-7 {
  margin-top: 30%;
  .mission {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    line-height: 37px;
  }
  .title {
    margin-top: 8%;
    p {
      font-size: 60px;
      font-weight: 400;
      line-height: 60px;
    }
  }

  .img-wrap {
    margin-top: 10%;
    padding-bottom: 40rem;
    .joy {
      width: 350px;
    }
    .joy-small {
      width: 170px;
      position: relative;
      z-index: 2;
      margin-top: -13%;
      margin-left: 45%;
    }

  }
}

@media (min-width: 1024px) {

  .block-1 {
    .title {
      margin-top: 7%;
      p {
        font-size: 100px;
        line-height: 100px;
        width: 50%;
      }
    }
    .suitcase {
      width: 320px;
      right: 45%;
      top: 25rem;
    }
    .bed {
      width: 480px ;
      height: 800px;
      right: 0;
      top: 0;
    }
  }
  .block-2 {
    margin-top: 34%;

    .link-wrap {
      p {
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        width: 52%;
      }
    }
  }
  .block-3 {
    margin-top: 20%;
    padding-left: 40px;
    padding-bottom: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title p {
      font-size: 52px;
      line-height: 62px;
      width: 19rem;
    }
    .content {
      margin-top: 0;
      display: flex;
      justify-content: right;
      p {
        font-size: 28px;
        font-weight: 400;
        line-height: 43px;
        width: 85%;
      }
    }

  }
  .block-4 {
    .title {
      position: relative;
      z-index: 2;
      p {
        font-size: 100px;
        line-height: 100px;
        width: 96%;
      }
    }
    .flex {
      display: flex;
      flex-direction: row-reverse;

      img , .list{
        width: 50%;
      }
      img {
        height: 716px;
        z-index: 1;
        margin-top: -31.5%;
      }
      .list {
        margin-top: 65px;

        .list-item {
          width: 424px;

          p {
            font-size: 28px;
            font-weight: 400;
            line-height: 43px;
            padding: 7% 0 7% 0;
          }
          span {
            padding-top: 7%;
            font-size: 28px;
            line-height: 43px;
          }
        }
      }
    }
  }
  .block-5 {
    margin-top: 25%;
    .content {
      p {
        font-size: 100px;
        font-weight: 400;
        line-height: 100px;
        width: 100%;
      }
      img {
        width: 360px;
        margin-top: 5%;
      }

    }
  }
  .block-6 {
    margin-top: 20%;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .title {
      p {
        font-size: 60px;
        line-height: 60px;
        width: 80%;
      }
    }

    .text {
      width: 58%;
      p {
        font-size: 28px;
        line-height: 43px;
      }
      .first-p {
        margin-top: 0;
      }
    }
  }

  .adaptive-hr {
    margin-top: 12%;
    display: block;
    border: 1px solid #303030;
  }

  .block-7 {
    .mission {
      font-size: 26px;
      line-height: 40px;
    }
    .title {
      position: relative;
      z-index: 2;
      margin-top: 3%;
      p {
        font-size: 100px;
        font-weight: 400;
        line-height: 100px;
        width: 70%;
      }
    }
    .img-wrap {
      .joy {
        width: 489px ;
        margin-left: 26%;
        margin-top: -72%;
      }
      .joy-small {
        width: 183px;
        margin-top: -83.5%;
        margin-left: 72%;
      }
    }
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 100%;
  }
  .block-1 {
    margin: 0;
    .title {
      margin-top: 5%;
      p {
        font-size: 90px;
        line-height: 90px;
      }
    }
    .suitcase {
      width: 247px;
      height: 249px;
      right: 56%;
      top: 14rem;
    }
    .bed {
      width: 371px;
      height: 620px;
      top: -118px;
      left: 50%;
    }
  }
  .block-2 {
    margin: 0;

    .link-wrap {
      width: 100%;
      position: relative;
      z-index: 2;
      margin-top: -17%;

      p {
        font-size: 30px;
        line-height: 36px;
        width: 31%;
        margin-top: 2%;
      }
    }
  }
  .block-3 {
    padding-left: 240px;

    .title {
      p {
        font-size: 52px;
        line-height: 52px;
      }
    }
    .content {
      justify-content: center;
      p {
        font-size: 24px;
        line-height: 37px;
        width: 63%;
      }
    }

  }
  .block-4 {
    .title {
      margin-top: 20%;
      p {
        font-size: 90px;
        line-height: 90px;
        width: 64%;
      }
    }
    .flex {
      img {
        width: 630px;
        height: 940px;
        margin-top: -42%;
      }
      .list {
        margin-top: 6.5%;

        .list-item {
          width: 602px;
          p {
            padding: 5% 0 5% 0;
          }
          span {
            padding-top: 5%;
            padding-left: 2%;
          }
        }
      }
    }
  }
  .block-5 {
    margin-top: 15%;
    .content {
      p {
        font-size: 90px;
        line-height: 90px;
      }
      img {
        margin-top: 4%;
      }
    }
  }
  .block-6 {
    .title {
      width: 33.5%;
      p {
        font-size: 48px;
        line-height: 52px;
        width: 60%;
      }
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 66.5%;

      p {
        margin: 0;
        font-size: 24px;
        line-height: 37px;
      }
      .first-p {
        margin-right: 48px;
      }
    }
  }

  .adaptive-hr {
    margin-top: 6%;
 }
  .block-7 {
    margin-top: 25%;
    .title {
      padding-left: 210px;
      margin-top: 1%;
      p {
        font-size: 90px;
        line-height: 90px;
        width: 45%;
      }
    }
    .mission {
      padding-left: 210px;
    }
    .img-wrap {
      .joy {
        margin-left: 36%;
        margin-top: -55%;
      }
      .joy-small {
        width: 184px;
        margin-top: -64.5%;
        margin-left: 70%;
      }
    }
  }
}

@media (min-width: 1512px) {
  .block-1 {
    .title {
      p {
        font-size: 120px;
        line-height: 120px;
      }
    }
    .bed {
      width: 480px;
      height: 800px;
      top: -50px;
      left: 52%;
    }
    .suitcase {
      width: 320px;
      height: 320px;
      right: 54%;
      top: 23rem;
    }
  }
  .block-2 {
    margin-top: 13.5%;

    .link-wrap {
      p {
        font-size: 40px;
        line-height: 48px;
        width: 37%;
      }
    }
  }
  .block-3 {
    .title {
      p {
        font-size: 64px;
        line-height: 72px;
        width: 100%;
      }
    }
    .content {
      p {
        font-size: 28px;
        line-height: 43px;
        width: 67%;
        margin-left: -9%;
      }
    }
  }
  .block-4 {
    .title {
      margin-top: 13%;
      p {
        font-size: 120px;
        font-weight: 400;
        line-height: 120px;
        width: 76%;
      }
    }
    .flex {
      img {
        width: 670px ;
        height: 1000px;
      }
      .list {
        width: 53%;
        margin-top: 116px;

        .list-item {
          width: 692px;
          p {
            padding: 4% 0 4% 0;
          }
          span {
            padding-top: 4%;
          }
        }
      }
    }
  }
  .block-5 {
    .content {
      p {
        font-size: 120px;
        line-height: 120px;
      }
      img {
        width: 453px;
        height: 454px;
      }
    }
  }
  .block-6 {
    .title {
      p {
        font-size: 80px;
        line-height: 80px;
        width: 90%;
      }
    }
    .text {
      p {
        font-size: 28px;
        font-weight: 400;
        line-height: 43px;
      }
      .first-p {
        margin-right: 69px;
      }
    }
  }
  .block-7 {
    margin-top: 35%;
    .img-wrap {
      .joy {
        width: 640px;
        height: 720px;
        margin-left: 33%;
        margin-top: -62%;
      }
      .joy-small {
        width: 240px;
        height: 240px;
        margin-top: -71%;
        margin-left: 72%;
      }
    }
    .title {
      padding-left: 160px;
      p {
        font-size: 120px;
        line-height: 120px;
        width: 52%;
      }
    }
    .mission {
      padding-left: 160px;
      font-size: 28px;
      line-height: 43px;
    }
  }
}

@media (min-width: 1920px) {
  .block-1 {
    .title {
      p {
        width: 42%;
      }
    }
    .bed {
      width: 520px;
      height: 866px;
      top: -25%;
      left: 59%;
    }
    .suitcase {
      right: 45%;
      top: 23rem;
    }

  }
  .block-2 {
    .link-wrap {
      p {
        width: 29%;
        margin-top: 1.5%;
      }
    }
  }
  .block-3 {
    padding-left: 441px;
    .content {
      justify-content: start;
      p {
        width: 58%;
        padding-left: 5%;
      }
    }
  }
  .block-4 {
    .title {
      margin-top: 15%;
      p {
        width: 60%;
      }
    }
    .flex {
      img {
        width: 840px;
        height: 1255px;
      }
      .list {
        margin-top: 149px;
        .list-item {
          width: 900px;
        }
      }
    }
  }
  .block-5 {
    margin-top: 15%;
  }
  .block-6 {
    margin-top: 10%;
  }
  .adaptive-hr {
    margin-top: 3%;
  }
  .block-7 {
    margin-top: 27%;
    .img-wrap {
      .joy {
        margin-top: -52%;
        margin-left: 37%;
      }
      .joy-small {
        margin-top: -59.5%;
        margin-left: 70%;
      }
    }
    .title , .mission {
      padding-left: 320px;
    }
  }
}


</style>